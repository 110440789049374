/* 10-03-23 Arul: Created the  seperate scssfile for Accordion  */

.p-accordion-header-link > svg {
  transform: rotate(90deg);
}

.p-highlight .p-accordion-header-link > svg {
  transform: rotate(180deg);
}

.accordion-container {
  border-radius: $borderRadius_x_small;
}

.accordion-container-border {
  border: $borderWidth_1 solid $light_grey;
  border-radius: $borderRadius_x_small;
}

.p-accordion-header {
  &.p-highlight {
    background-color: $hm_green_accent;
    color: $white;
    box-shadow: none;
    border-radius: $borderRadius_x_medium;
  }
}
.p-accordion-header a {
  color: $dark_grey;
}

.p-accordion .p-accordion-content {
  box-sizing: content-box;
  padding: 0px;
  border-left: 0px !important;
  border-right: 0px !important;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  background: none;
  border-left: 0px !important;
  border-right: 0px !important;
  border-radius: 0px !important;
}

.p-highlight a {
  color: $white;
}

.accordion_line {
  margin: $marginSmall 0px;
}

.p-accordion-header > a:hover {
  text-decoration: none !important;
}

.listItem {
  list-style: none;
  padding: 0px;
  margin: 0px;
  box-sizing: content-box;
}

.icon-cancel {
  margin-right: 5px;
  color: $light_red;
  &:hover {
    color: $dark_red;
    cursor: pointer;
  }
}

.icon-cancel-disabled {
  margin-right: 5px;
  color: $white;
}

.listItem .rounded {
  list-style: none;
  padding: 0px;
  margin: 0px;
  box-sizing: content-box;
  border-radius: $borderRadius_x_small;
}

.listContent {
  padding: $padding_medium_large;
  width: 100%;
  box-sizing: content-box;
  align-items: center;
}

.p-accordion-header-text {
  width: 100%;
}

.listContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: $borderWidth_x_small solid $light_grey;
}

.editDataTableHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 20px;
  font-size: 16px;
}

.editDataTableHeader span {
  margin: 0px 0px;
  width: 100%;
  text-align: center;
}

.protocolListContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: $borderWidth_1 solid $light_grey;
  border-radius: 6px;
  font-size: 16px;
  padding: 0;
  // width: 100%;
  margin: 0px 20px;
}

.protocolItemListContainer {
  display: flex;
  align-items: center;
  justify-items: center;
  align-content: center;
  flex-direction: row;
  flex: 1;
  padding-top: $padding_small_medium;
  padding-bottom: $padding_small_medium;
  cursor: pointer;

  &.no-cursor {
    cursor: default;
  }
}

.ProtocolItemListContent {
  display: flex;
  flex: 1;
  font-weight: $font-weight-bold;
  font-family: $font-family;
  align-content: center;
  // margin: 0 0 0 3.5%;
}

.protocolItemListLock {
  margin: 0 0 0 1.5%;
  padding: 0;
  // position: absolute;
  // left: $padding_medium_large*4;
  // color: $line_grey;
}

.ProtocolItemListPad {
  display: flex;
  flex: 1;
  padding: $padding_small_medium 0 $padding_small_medium;
  border-bottom: $borderWidth_x_small solid $line_grey;
  cursor: 'pointer';
}

.ProtocolItemListPadLast {
  padding: $padding_small_medium 0 $padding_small_medium;
  cursor: 'pointer';
}

.protocolTab a > svg {
  display: none;
}

/* PDF SCREEN */
.pdfContainer {
  width: 95%;
  height: 100vh;
  margin-right: 2.5%;
  margin-left: 2.5%;
}
