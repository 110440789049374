.accordion-header {
    border-radius: 5px;
    box-shadow: none;
    align-content: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
    // marginTop: 20px;
    background-color: transparent;
    border: 1px solid #ccc;
    transition: all 0.3s;
  
    &:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
        cursor: pointer;
    }

    &.expanded {
      // background-color: #E0EADD;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

    }
    &.expanded-color {
        background-color: #E0EADD;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
      }
  
  }