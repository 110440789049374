.status-container {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    height: auto;
    border-radius: $border_radius_4;
    margin-left: $marginSmall;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto; /* Adjusts the starting size of the element before free space is distributed according to flex-grow/shrink. */

    &.Inactive {
        background-color: $color_draft;
    }
    &.DRAFT {
        background-color: $color_draft;
    }

    &.ACTIVE {
        background-color: $color_active;
    }

    &.ARCHIVE {
        background-color: $color_archive;
    }

    &.DELETED {
        background-color: $color_deleted;
    }

    &.DEACTIVATED {
        background-color: $color_deleted;
    }

    &.DRAFT_DELETE {
        background-color: $color_deleted;
    }

    &.Private {
        background-color: $color_draft;

        &.clickable {
            cursor: pointer;
            :hover {
                background-color: $color_draft_hover;
            }
        }
    }

    &.Protected {
        background-color: $color_archive;

        &.clickable {
            cursor: pointer;
            :hover {
                background-color: $color_archive_hover;
            }
        }
    }

    &.Public {
        background-color: $color_active;

        &.clickable {
            cursor: pointer;
            :hover {
                background-color: $color_active_hover;
            }
        }
    }

    


    .status {
        font-size: $font_medium;
        font-family: $font-family;
        font-weight: bold;
        padding: $padding_small $padding_8;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        &.DRAFT {
            color: $color_draft_text;
        }

        &.Inactive {
            color: $color_draft_text;
        }

        &.ACTIVE {
            color: $color_active_text;
        }

        &.ARCHIVE {
            color: $color_archive_text;
        }

        &.DEACTIVATED {
            color: $color_deleted_text;
        }

        &.DELETED {
            color: $color_deleted_text;
        }

        &.DRAFT_DELETE {
            color: $color_deleted_text;
        }

        &.Private {
            color: $color_draft_text;
        }

        &.Protected {
            color: $color_archive_text;
        }

        &.Public {
            color: $color_active_text;
        }

        &:hover {
            text-decoration: none !important;
        }
    }
}