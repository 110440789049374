.result-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.result-list-item {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    cursor: pointer;

    &:hover {
        background-color: #E0EADD;
        border-radius: 6px;
    }
}

.expanded {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

}

.input-control {
    // height: $Height_30;
    flex: 1;
    display: flex;
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 5px;
    font-size: small;

    &:focus {
        box-shadow: none;
        outline: 0;
        border: 1px solid #5A8F5C;
    }
}

.filled-input-container {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    background-color: #eaeded;
    padding: 1rem 0.25rem;
}

.input-label {
    font-size: 12px;
    font-weight: 600;
    color: #636363;
    padding: 0;
    margin: 0  0  0 10px;
    display: flex;
}

.import-medication-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #000
}

.input-sidebarLabel {
    font-size: 0.8rem;
    font-weight: 600;
    color: #636363;
    padding: 0;
    margin: 0  0  0 0px;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;

    &.red {
        color: #880808;
    }
}
.input-placeholder {
    font-size: 0.8rem;
    font-weight: 400;
    color: #636363;
    padding: 0;
    margin: 0  0  0 0px;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;

    &.red {
        color: #880808;
    }
}