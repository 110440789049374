.dragDropDotGrid {
  margin-top: 10px;
  padding: 10px;
  border: 2px dotted #cdc8c8;
  justify-content: center;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 10px;
}

.previewImage {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.dropDownIconWidth {
  .p-dropdown-trigger {
    width: 30px;
  }
  .p-dropdown-label.p-inputtext {
    padding: 4px !important;
  }
  .p-dropdown {
    padding: 2px;
  }
}

.input-container-we {
  margin-top: -25px;

  .p-dropdown-label.p-inputtext {
    border: none;
    font-size: 14px;
    padding: 2px;
    display: flex;
    justify-content: start;
    align-items: center;
    color: #000;
    font-weight: 500;
    font-family: 'Titillium Web', sans-serif;
    margin-left: 15px;
  }
}

.dropdown-style {
  .p-dropdown-panel.p-component.p-ripple-disabled.p-connected-overlay-enter-done {
    z-index: 1002;
    position: absolute;
    top: 400.167px;
    left: 301px;
    min-width: 638.333px;
    transform-origin: center top;
  }
}

.notification-css {
  font-size: 13px;
  font-weight: 400;
  justify-content: flex-start;
  font-family: 'Titillium Web', sans-serif;
  color: #000;
  display: flex;
  margin-top: 10px;
}
.notification-css-grey {
  @extend .notification-css;
  color: $dark_grey;
}

.notification-css-modifiedBy {
  font-size: 13px;
  font-weight: 400;
  margin-left: 10px;
  justify-content: flex-start;
  font-family: 'Titillium Web', sans-serif;
  display: flex;
}

.workbook-css {
  font-size: 13px;
  font-weight: 400;
  justify-content: flex-start;
  font-family: 'Titillium Web', sans-serif;
  color: #000;
  display: flex;
}

.workbook-css-grey {
  @extend .workbook-css;
  color: $dark_grey;
}

.notification-css-title {
  @extend .notification-css;
  font-weight: 600;
}

.main-with-DataTable {
  font-family: 'Titillium Web', sans-serif;
  height: auto;
  position: initial;
  display: flex;
  font-size: 40px;
  color: #000000;
  box-sizing: content-box;
  border: 2px solid white;
  border-radius: 10px;
  background-color: rgb(247, 252, 247);
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 3px #9e9e9e;
  scrollbar-shadow-color: #00534c;
  transition: all 0.5s;
}
.notification-model {
  @extend .form-control;
  text-align: start;
  font-size: $font_medium;
  font-weight: $font-weight-bold;
  font-family: $font-family;
  width: 100%;
  display: flex;
  padding-left: $paddingSet_medium_large;
  margin-bottom: $marginSmall;
  color: $black;
  border-radius: 4px;
  &:focus {
    background-color: $white;
    border: $borderWidth_1 solid $hm_green;
  }
  &:disabled {
    color: $black;
    background-color: $white;
    border: $borderWidth_1 solid $grey;
  }
}

//  .overflow-info-container-General-Information {
//      overflow-y: auto;
//      height: 92%;
//  }

.calenderWidthStyle {
  display: flex;
  flex-direction: row;

  .p-inputtext {
    height: 30px !important;
    // width: 100vh;
    flex: 1;
    // width: 100%;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
  }
  .button,
  input,
  optgroup,
  select,
  textarea {
    height: 30px !important;
  }
  [type='button']:not(:disabled),
  [type='reset']:not(:disabled),
  [type='submit']:not(:disabled),
  button:not(:disabled) {
    height: 30px !important;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background: $hm_green;
    border-color: $hm_green;
    position: sticky;
  }
  [type='button'],
  [type='reset'],
  [type='submit'],
  button {
    height: 30px !important;
  }

  .p-calendar-w-btn-right .p-inputtext {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}

.contentJustifyCenter {
  font-size: 15px;
  font-family: 'Titillium Web', sans-serif;
  font-weight: bold;
  align-items: center;
  display: flex;
  justify-items: center;
}
.contentTextgreenColor {
  color: $hm_green_accent;

  &:hover {
    color: $hm_green;
    cursor: pointer;
  }
}
.contentTextInactive {
  color: $grey;
}

.titleForm {
  font-size: $font_large;
  font-family: 'Titillium Web', sans-serif;
  font-weight: bold;
  color: $black;
}

.notificatonTextColor {
  height: '110px';
  verticalalign: 'top';
}

.hoverIconGrey {
  padding: 4px;
  color: $grey;
  &:hover {
    border-radius: 20px;
    cursor: pointer;
    background-color: $dark_grey;
    // color: $black;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Black overlay with opacity */
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay .play-button {
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 20px);
}

.overlay .play-button .icon {
  color: $grey;

  &:hover {
    color: $white;
  }
}

.thumbnail-container {
  position: relative;
  width: fit-content; /* Adjust as needed */
}

.thumbnail-wrapper {
  position: relative;
}

.a1SubITems {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // justify-content: space-between;
  flex: 1;
}

.a1SubItemsContent {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  &.notDataContentText {
    font-size: 16px;
    justify-content: center;
    align-items: center;
  }
}

.a1Content {
  display: flex;
  flex-direction: column;
  margin-left: $margin_32;
}

.notification-question-container {
  .disabled {
    opacity: 0.9;
  }

  .disabled-button {
    cursor: not-allowed;
    pointer-events: none; /* Optional: Prevents click events on disabled buttons */
  }
  .left-container {
    flex: 1 1 80%;
    padding: 0;
    max-width: 1200px;

    .notification-header-text {
      display: flex;
      font-weight: bold;
      border-bottom: 1px solid #e0e0e0;
    }
  }
  .right-container {
    flex: 1 1 20%;
    position: sticky;
    top: 20px;
    padding: 0;
    max-width: 400px;

    .notification-header-text {
      display: flex;
      justify-content: center;
      padding-bottom: 5px;
      font-size: 20px;
    }
  }

  .questions-container {
    display: flex;
    flex-direction: column;
    max-width: 1024px;
    margin: 0 auto;
    gap: 10px;
    padding: 10px 16px;
  }

  .question-box {
    background-color: #f7faf6;
    border-radius: 8px;
    box-shadow: 0 4px 3px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 10px;
    overflow: hidden; /* Hide content overflow during transition */
    transition: all 0.2s ease; /* Smooth transition for expansion */
  }
  .question-box.selected {
    border-left: 4px solid #5a8f5c;
  }

  .question-box-header {
    display: flex;
    justify-content: space-between;
  }
  .answers-container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    .option {
      display: flex;
      padding: 4px;
      border-radius: 4px;
      align-items: center;
      transition: background-color 0.3s ease;

      input[type='radio'],
      input[type='checkbox'] {
        margin-right: 10px;
        display: flex;
        flex: 1 1 5%;
        transform: scale(1.5);
      }

      .option-label {
        font-size: 16px;
        display: flex;
        flex: 1 1 95%;
      }

      //   &:hover {
      //     background-color: #f5f5f5;
      //   }
    }

    .option-input {
      display: flex;
      align-items: center;
      margin-right: 10px;
    }

    .search-custom-input {
      width: 50%; /* Adjust this width as needed */
      max-width: 300px; /* Set a maximum width to control the input size */
      margin-bottom: 0; /* Override any default margin-bottom */
    }

    .option-add-button {
      margin-top: 12px;
      padding: 8px 16px;
      background-color: #007bff; // Primary button color
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #0056b3; // Darker shade on hover
      }
    }
  }

  .copy-icon {
    cursor: pointer;
    color: #0479b3; // Primary button color
    font-size: 30px;
    padding: 5px;
    margin-right: 2px;
  }
  .copy-icon:hover {
    font-size: 30px;
    padding: 5px;
  }

  .remove-icon {
    cursor: pointer;
    color: #880808;
    font-size: 30px;
    padding: 5px;
  }

  .remove-icon:hover {
    background-color: #ffe6e6; /* Light red background on hover */
    font-size: 30px;
    padding: 5px;
    border-radius: 50%;
    transition: background-color 0.3s; /* Smooth transition for background color */
  }
  select {
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 6px 12px;
    font-size: 16px;
    color: #495057;
    background-color: #fff;
    transition:
      border-color 0.3s,
      box-shadow 0.3s;
  }

  select:focus {
    border-color: #5a8f5c;
    box-shadow: 0 0 0 0.2rem rgba(90, 143, 92, 0.25);
    outline: none;
  }
  .ham-bar {
    margin-right: 10px;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }

  .question-type-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 16px;
  }

  .question-type-button {
    background-color: #ffffff; /* White background */
    border: 1px solid #d1d5da;
    border-radius: 4px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
    transition:
      background-color 0.3s,
      box-shadow 0.3s;
  }

  .question-type-button:hover {
    background-color: #f6f8fa; /* Light grey background on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
  }

  .question-type-button.active {
    border-color: #00534c;
    background-color: #f7faf6;
  }
  .question-type-button.active:hover {
    border-color: #5a8f5c;
    background-color: #5a8f5c; /* Light grey background on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
    color: #ffffff;
  }
  .input-wrapper,
  .text-wrapper {
    position: relative;
    margin-bottom: 12px;
  }

  .input-wrapper::after,
  .text-wrapper::after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #5a8f5c;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }

  .input-wrapper.focused::after,
  .text-wrapper.focused::after {
    transform: scaleX(1);
  }

  .text-wrapper input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #dadce0;
    padding: 8px 0;
    font-size: 16px;
    background-color: transparent;
  }
  .input-wrapper input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #dadce0;
    padding: 8px 0;
    font-size: 16px;
    background-color: transparent;
  }

  .input-wrapper input:focus,
  .text-wrapper input:focus {
    outline: none;
  }
}

.notification-copy-container {
  display: grid;
  grid-template-columns: 16fr 1fr;
  padding: 6px 10px;
  align-items: center;
  
  cursor: pointer;

  &:hover {
    background-color: #E0EADD;
  }
}
