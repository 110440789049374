.stat-count {
    background-color: #00534C;
  color: white;
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  &.same {
    background-color: #D3D3D3;
    color: #3d3d3d;
    border: 1px solid #D3D3D3;

    &:hover {
        background-color: #C0C0C0;
    }

    &:active {
        border: 1px solid #00534C;
    }

    &.selected {
        border: 1px solid #00534C;
    }
  }

  &.new {
    background-color: #C3DBB0;
    color: #037F02;
    border: 1px solid #C3DBB0;

    &:hover {
        background-color: #B7CFB0;
    }

    &:active {
        border: 1px solid #00534C;
    }

    &.selected {
        border: 1px solid #00534C;
    }
  }

  &.removed {
    background-color: #FFC7CE;
    color: #DC143C;
    border: 1px solid #FFC7CE;

    &:hover {
        background-color: #FFB7C5;
    }

    &:active {
        border: 1px solid #00534C;
    }

    &.selected {
        border: 1px solid #00534C;
    }
  }
}

.comparison-rows {
  tr {
    border: none;
    
  }

  td {
    border: 1px solid #eaeded !important;
    background-color: #fafbfb !important;
  }

  .rows-container {
    display: flex;
    flex-direction: row;
    gap: 5%;

    .rows-container-item {
      flex: 1;
    }
  }
}

.transparent-stat-count {
  background-color: transparent;
  color: transparent;
  border: 1px solid transparent;
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
}
.equipment-comparison-container {
  width: 100%;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;


  .equipment-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }

  .equipment-table-cell {
    padding: 12px;
    vertical-align: top;
    width: 50%;
  }

  .equipment-field-value {
    border: 1px solid #e0e0e0;
    color: #000;
    font-weight: 500;
    font-size: 15px;
    padding: 8px;
    border-radius: 5px;
    min-height: 30px;
    background-color: white;
  }

  .equipment-options-cell {
    padding: 12px;
  }

  .equipment-options-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .equipment-required-field {
    color: red;
  }
  .field-values td {
    padding: 12px;
    vertical-align: top;
  }
  
  .before-cell {
    width: 50%;
    border-right: 1px solid #e0e0e0;
  }
  .field-value {
    border: 1px solid #e0e0e0;
    color: #000;
    font-weight: 500;
    font-size: 15px;
    padding: 8px;
    border-radius: 5px;
    min-height: 30px;
    background-color: white;
  }
  
  .after-cell {
    width: 50%;
  }
}
