/* ResizableDivs.css */
.resizeable-container {
    display: flex;
    height: 100%;
    width: 100%;
  }
  
  .resizeable-panel {
    overflow: auto;
    min-width: 10%;  /* Ensure that panels don't disappear entirely */
    height: 100%;
  }
  
  .resizeable-divider {
    cursor: ew-resize;
    background-color: #333;
    width: 4px;
    height: 100%;
  }
  