.login-container {
    display: flex;
    height: 100vh; /* Full view height */
    width: 100vw; /* Full view width */
}

.company-logo-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    width: 75%;
    gap: 16px;
}

.login-logo {
    width: 15%;
    height: auto;
    aspect-ratio: 1/1;
}

.title-company {
    // flex: 1;
    display: flex;
    font-size: 52px;
    font-weight: bold;
    justify-content: center;
    align-items: center;

    font-family: 'Titillium Web', sans-serif;
    color: #00534C;
    margin: 0;
    text-align: center;
}

.login-form {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-items: center;
    // gap: 10px; /* Spacing between form elements */
    /* Give a box shadow to raise it up */
    box-shadow:  2px 0px 3px rgba(0, 0, 0, 0.5);
    background-color: #E0EADD;
    margin: 0;
    z-index: 1;
}

.login-form .login-title {
    color: #FFF !important;
    font-size: 20px;
    font-weight: bold;
    font-family: 'Titillium Web', sans-serif;
    margin: 10px 0 0px 10px;
    display: flex;
    align-items: flex-end;
}

.login-form .login-form-container {
    width: 70%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 0px; /* Spacing between form elements */
    background-color: #00534C;
    border-radius: 20px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.8);

    max-height: 80vh;  // Adding this to prevent overflow
    overflow-y: auto;  // Adding scrolling if content is too long
    
    // Style the scrollbar
    &::-webkit-scrollbar {
      width: 8px;
    }
    
    &::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0.1);
    }
    
    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.3);
      border-radius: 4px;
    }
}

.login-form svg {
    color: #FFF;
    position: absolute;
    top: 12.5px;
    left: 10px;
}

.login-form .login-input-container {
    position: relative;
    margin: 0px 20px;
    margin-bottom: 10px;  // Adding space between input groups
}

.login-form .login-error{
    font-size: 13px;
    font-family: 'Titillium Web', sans-serif;
    align-items: center;
    display: flex;
    color: #FF4C4C;
    margin: 0px 10px;  // Adding proper margins
    background-color: rgba(255, 76, 76, 0.1);
    border-radius: 4px;
}

.login-form .login-input {
    width: 100%;
    // margin: 10px 10px 5px 10px;
    color: #FFF;
    font-size: 16px;
    padding: 10px 10px 10px 40px;
    border-top: 0px solid #FFF;
    border-left: 0px solid #FFF;
    border-right: 0px solid #FFF;
    border-radius:  0px;
    border-bottom: 1px solid #FFF;
    background: transparent;
    font-family: 'Titillium Web', sans-serif;

    &::placeholder {
        color: rgba(255, 255, 255, 0.6);
    }
      
      &:focus {
        outline: none;
        border-bottom: 2px solid #FFF;
    }
}

.login-form .login-button {
    width: 100%;
    // margin: 10px 10px 5px 10px;
    font-size: 16px;
    color: #00534C;
    margin: 20px 0px;
    padding: 10px 20px;
    border-radius: 20px;
    background: #E0EADD;
    border: 0px solid #616161;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
    font-family: 'Titillium Web', sans-serif;

    &:hover {
        background: #C4CDC1;
        color: #003E39;
        cursor: pointer;
    }
}

.login-background {
    flex: 1.5;
    background-image: url('../../../../assets/images/Hinckley_Medical_Home_NTP_1920x1245.jpg'); /* Replace with your image path */
    background-size: cover;
    background-position: center;
}

.login-form .signup-title {
  color: #FFF !important;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Titillium Web', sans-serif;
  margin: 10px 0 0px 10px;
  display: flex;
  align-items: flex-end;
}

.signup-button {
    // Positioning below the green container
    margin-top: 10px;
    
    // Button styling
    background: transparent;
    border: none;
    color: #00534C;
    font-size: 16px;
    font-family: 'Titillium Web', sans-serif;
    padding: 10px 20px;
    cursor: pointer;
    text-decoration: underline;
    
    // Hover effect
    &:hover {
      color: #003E39;
    }
  }

.login-description {
  font-size: 14px;
  font-family: 'Titillium Web', sans-serif;
  color: #FFF;
  margin: 0px 20px;
}

.password-fields-container {
    display: flex;
    gap: 20px;
    
    // Make it responsive
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 0px;
    }
  }
  
  .password-field {
    flex: 1;
    
    .login-title {
      margin: 10px 0 0px 0px !important;
    }
    
    .login-input-container {
      position: relative;
      margin: 0px 10px;
      margin-bottom: 10px;
    }
  }

  .department-select {
    width: 100%;
    color: rgba(255, 255, 255, 0.6);
    font-size: 16px;
    padding: 10px 10px 10px 40px;
    border-top: 0px solid #FFF;
    border-left: 0px solid #FFF;
    border-right: 0px solid #FFF;
    border-radius: 0px;
    border-bottom: 1px solid #FFF;
    background: transparent;
    font-family: 'Titillium Web', sans-serif;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        color: rgba(255, 255, 255, 0.8);
    }
}

.department-select-container {
    .login-input {
        display: none;
    }
}

.department-tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 0.25rem;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.department-tag {
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  font-size: 14px;
  color: #333;
  display: inline-flex;
  align-items: center;

  transition: background-color 0.2s ease;
  
  &:hover {
    background-color: #dee2e6 !important;
  }
}

.create-user-title {
  width: 100%;
  padding: 0 140px;
  // margin-bottom: 10px;
  
  h3 {
      font-weight: bold;
      color: black;
      margin: 0;
      text-align: left;
      font-family: 'Titillium Web', sans-serif;
  }
}

.login-form .login-department-name {
  color: #FFF !important;
  font-size: 30px;
  font-weight: bold;
  font-family: 'Titillium Web', sans-serif;
  margin: 0px 0 0px 10px;
  display: flex;
  align-items: flex-end;
}