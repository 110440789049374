.user-status-container {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    cursor: pointer;
    height: auto;
    border-radius: $border_radius_4;
    margin-left: $marginSmall;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto; /* Adjusts the starting size of the element before free space is distributed according to flex-grow/shrink. */


    &.ACTIVE {
        background-color: $color_active;
    }

    &.SUSPENDED {
        background-color: $color_archive;
    }

    &.DELETED {
        background-color: $color_deleted;
    }


    .user-status {
        font-size: $font_medium;
        font-family: $font-family;
        font-weight: '500';
        padding: $padding_small $padding_8;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        &.ACTIVE {
            color: $color_active_text;
        }

        &.SUSPENDED {
            color: $color_archive_text;
        }

        &.DELETED {
            color: $color_deleted_text;
        }

        &:hover {
            text-decoration: none !important;
        }
    }
}