.loading-background{
    background: #000000ae;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.loading-background h3 {
    color: #E0EADD;
    font-size: 30px;
    z-index: 10000 !important;

}
.loading-background .load{
    color: #e0eadd;
    z-index: 10000 !important;
}

.remove-loading-container {
  position: absolute;
  top: 20px;
  right: 40px;
  cursor: pointer;
  color: #e0eadd;
  z-index: 10000 !important;
  background: #000000ae;
  border-radius: 50%;
  padding: 4px 6px;

  &:hover {
    background: #00000066;
    color: #e0eadd;
  }
}

.remove-loading-container .remove-loading {
  font-size: 20px;
}
