.loading-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #F3F3F3; /* Change the background color if needed */
    z-index: 9999; /* Ensure it's above other content */
}

.logo-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    // margin-bottom: 20px; /* Adjust the spacing between the logo and the loading progress bar */
}

.logo {
    width: 30%; /* Adjust the size of your logo */
    height: auto;
    padding: 0px;
    margin: 0px;
    /* Add any other styles for your logo */
}

.pulse-background {
    position: absolute;
    width: 60vw;
    height: 60vw;
    max-width: 600px;
    max-height: 600px;
    background-color: rgba(0, 83, 76, 0.3); /* Adjust the color and opacity of the pulse effect */
    border-radius: 50%;
    animation: pulse 2s infinite;
    padding: 0;
    // margin-bottom: 2.25%;
}
// .pulse-background {
//     position: absolute;
//     top: 35%;
//     left: 35%;
//     // transform: translate(-50%, -50%);
//     width: 60vw;
//     height: 60vw;
//     background-color: rgba(0, 83, 76, 0.3); /* Adjust the color and opacity of the pulse effect */
//     border-radius: 50%;
//     animation: pulse 2s infinite;
//     padding: 0;
// }

.loading-bar {
    width: 40%;
    height: 4px; /* Adjust the height of the loading progress bar */
    background-color: #007bff; /* Adjust the color of the loading progress bar */
    margin-top: 20px; /* Adjust the spacing between the logo and the loading progress bar */
}

@keyframes pulse {
    0% {
        transform: scale(0.65);
        opacity: 0.7;
    }
    50% {
        transform: scale(1);//1
        opacity: 1;
    }
    100% {
        transform: scale(0.65);
        opacity: 0.7;
    }
}
