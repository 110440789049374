.settings-tab-container {
  display: flex;
  flex-direction: row;
  // justify-content: flex-start;
  // align-items: center;
  padding: 0.5rem 2rem 0.5rem 1rem;
  cursor: pointer;
  border-radius: 6px;
  // background-color: #E0EADD;

  &:hover {
    background-color: #eff4ee;
  }
  &.active {
    background-color: #e0eadd;
  }
}

.department-logo-settings {
  width: 12rem;
  height: auto;
  margin: 0;
  padding: 0;
  // border-radius: 50%;
  // object-fit: cover;
  aspect-ratio: 1;
  background-color: #fff;
  cursor: pointer;
}

.edit-image-container {
  position: absolute;
  display: flex;
  left: 3.5rem;
  top: 16.5rem;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem;
  flex: 1;
  background-color: #00534c;
  cursor: pointer;
  // border: 1px solid #FFF;
  color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

  &.disabled {
    background-color: #8cb181;
    cursor: default;

    &:hover {
      background-color: #8cb181;
    }
  }

  &:hover {
    background-color: #00372F;
  }
}

.settings-button {
  background-color: '#00534C';

  color: '#FFF';

  // &:hover {
  //     background-color: $hm_green_background;
  //     color: $black;
  //     border: $borderWidth_small solid $hm_green;
  // }

  // &:active {
  //     background-color: $hm_green !important;
  //     border-color: $hm_green !important;
  // }
}

.c-dropdown_list-settings {
  position: absolute;
  top: 5.5rem;
  left: 6.5%;
  width: 85%;
  box-sizing: border-box;
  list-style: none;
  padding-left: 0px;
  border: 1px solid #919191;
  border-radius: 6px;
  padding: 5px 0px;
  color: #000;
  background-color: white;
  //Add shadow
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.settings-general-label {
  font-size: 0.9rem;
  // font-family: $font-family;
  color: '#000';
  display: flex;
  margin-bottom: 5px;
  font-weight: bold;
  margin-left: 10px;
  margin-top: 5px;
}

.radio-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
  margin-top: 5px;
}

.radioBtnColorSettings {
  // width: 20px;
  // height: 20px;

  .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    background-color: '#000';
  }

  .p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: '#00534C';
    background: '#999';
  }

  .p-radiobutton .p-radiobutton-box {
    width: 16px;
    height: 16px;
  }
}
