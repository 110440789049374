
.public-container {
    position: initial;
    min-height: 94vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    box-sizing: content-box;

    margin: 58px 0px 0px 0px;
    padding: 0 0 0px 0;
    // border: 2px solid white;
    // border-radius: 10px;

    background-color: white;
    // font-size: 40px;
    // color: #000000;
    // box-shadow: 0 0 3px #9E9E9E;
    // scrollbar-shadow-color: #00534C;
    transition: all 0.50s;
    font-family: 'Titillium Web', sans-serif;

    // &.with-sidebar {
    //     margin: 62px 10px 10px 240px;
    //     transition: all 0.5s;
    // }
}

.public-panel {
    // overflow: auto;
    height: 100%;
    display: flex;
    // flex: 1;
    // flex-direction: column;
    // border: 2px solid white;
    // border-radius: 10px;
    // box-shadow: 0 0 3px #9E9E9E;
    // scrollbar-shadow-color: #00534C;
    // transition: all 0.10s;
    // font-family: 'Titillium Web', sans-serif;

}

.public-sidebar-pdf {
    display: flex;
    flex-direction: column;
    width: 35vw;
    // flex: 1;
    min-width: 360px;
    overflow-y: auto;
    background-color: #f8f9f9;
    // padding: 0.5rem;
}


.public-sidebar {
    display: flex;
    flex-direction: column;
    width: 20vw;
    // flex: 1;
    min-width: 300px;
    overflow-y: auto;
    background-color: #f8f9f9;
    padding: 0.5rem;
    height: calc(100vh - 102px);
  
    position: fixed;
    // top: 62px;
    margin: 0 0 10px 10px;
    /* Top, right, bottom, left */
    padding: 15px;
    left: -100%;
    transition: all 0.40s;
    border-radius: 12px;
    box-sizing: content-box;
    box-shadow: 0 0 3px #9E9E9E;
    z-index: 1;

    &.active {
        left: 0;
    }
}

.public-screen-container {
    height: calc(100vh - 4px);
    display: flex;
    transition: all 0.5s;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
}

.department-logo-public {
    width: 90px;
    height: auto;
    margin: 0;
    padding: 0;
    border-radius: 50%;
    // object-fit: cover;
    aspect-ratio: 1;
    background-color: #f8f9f9;
}

.department-logo-public-regular {
    width: 90px;
    height: auto;
    margin: 0;
    padding: 0;
    // border-radius: 50%;
    // object-fit: cover;
    aspect-ratio: 1;
    background-color: #f8f9f9;
}

.categoryText {
    font-family: 'Titillium Web', sans-serif;
    // align-items: flex-end;
    font-size: 14px;
    // margin-top: 2px;
    //Stye on 1 line with ellipsis
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    padding: 0;
    flex: 1;
}

.publicListContainer {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 0rem;
    // gap: 0.5rem;
    transition: all 5s;
}

.publicListRow {
    flex: 1;
    overflow-y: auto;
    border: 1px solid #e0e0e0;
    // border-radius: 0.5rem;
    padding: 6px 6px;
    flex-direction: column;
    // transition: all 0.5s;

    &.hidden {
        display: none;
        flex: 0
    }

    &.noBorder {
        border: none;
    }

}


.listItemContainer {
    width: 100%;
    display: flex;
    gap: 0.65rem;
    justify-items: center;
    align-items: center;
    padding: 0.5rem 0.25rem;
    border-radius: 6px;
    cursor: pointer;
    color: black;
    font-weight: 400;

    &:hover {
        background-color: #E0EADD;
    }

    &.selected {
        color: white;
        background-color: #8CB181;
        font-weight: 600;
    }
}

.container-404 {
    display: flex;
    height: 100%; /* Full view height */
    width: 100vw; /* Full view width */
}

.left-container-404 {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
    // gap: 10px; /* Spacing between form elements */
    /* Give a box shadow to raise it up */
    // box-shadow:  2px 0px 3px rgba(0, 0, 0, 0.5);
    background-color: #E0EADD;
    // margin: 0;
    // z-index: 1;
}

.container-background-404 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
    background-color: #00534C;
    border-radius: 20px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
    margin: 16px;
    z-index: 1;
}

.text404Container {
    flex: 2;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-items: center;
    gap: 0px; /* Spacing between form elements */
    // background-color: #00534C;
    // border-radius: 20px;
    // box-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
}

.icon404Container {
    flex: 1;
    padding: 20px;
    display: flex;
    // flex-direction: column;
    // gap: 0px; /* Spacing between form elements */
    // background-color: #00534C;
    // border-radius: 20px;
    // box-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
}

.icon404 {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    // border-radius: 50%;
    // object-fit: cover;
    aspect-ratio: 1;
    color: #E0EADD;
    // background-color: #E0EADD;
}

.text404 {
    color: #E0EADD;
    font-size: 168px;
    font-weight: bold;
    font-family: 'Titillium Web', sans-serif;
    margin: 10px 0 0px 0px;
    display: flex;
    align-items: flex-end;
}

.text404SubTitle {
    color: white;
    font-size: 48px;
    font-weight: bold;
    font-family: 'Titillium Web', sans-serif;
    margin: -10px 0 0px 0px;
    display: flex;
    align-items: flex-end;
}

.text404Text {
    color: #A3A3A3;
    font-size: 16px;
    font-weight: 600;
    margin: 10px 0 0px 0px;
    display: flex;
    align-items: flex-end;
    text-align: center;
}

.background-404 {
    flex: 1.25;
    background-image: url('../../../../assets/images/Hinckley_Medical_Home_NTP_1920x1245.jpg'); /* Replace with your image path */
    background-size: cover;
    background-position: center;
}

.backButton404 {
    // margin: 10px 10px 5px 10px;
    font-size: 16px;
    color: #00534C;
    margin: 20px 0px;
    padding: 10px 20px;
    border-radius: 20px;
    background: #E0EADD;
    border: 0px solid #E0EADD;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
    font-family: 'Titillium Web', sans-serif;
    font-weight: 600;
    cursor: pointer;

    &:hover {
        background: #7c9b73;
    }
}

.locked-protocol-screen-overlay {
    background-color: #000000A0;
    flex: 1;
    // z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.locked-message-box {
    background-color: #00000030;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    max-width: 50%;
}

.locked-message-button {
    // margin: 10px 10px 5px 10px;
    font-size: 16px;
    color: #00534C;
    margin: 20px 0px;
    padding: 10px 20px;
    border-radius: 20px;
    background: #00000030;
    // border: 0px solid #E0EADD;
    box-shadow: 0 0 px rgba(0, 0, 0, 0.8);
    font-family: 'Titillium Web', sans-serif;
    font-weight: 600;
    cursor: pointer;
    color: white;

    &:hover {
        background: #E0EADD30;
        color: white;
    }
}

.locked-message-text {
    color: #f3f3f3;
    font-size: 18px;
    font-weight: '600';
    font-family: 'Titillium Web', sans-serif;
    margin: 10px 0;
    display: flex;
    align-items: flex-end;
    text-align: center;
}

.locked-message-title {
    color: white;
    font-size: 24px;
    font-weight: bold;
    font-family: 'Titillium Web', sans-serif;
    margin: 10px 0;
    display: flex;
    align-items: flex-end;
    text-align: center;
}

.keychain-title {
    color: black;
    font-size: 1.0rem;
    font-weight: bold;
    font-family: 'Titillium Web', sans-serif;
    padding: 10px 0;
    align-items: center;
    display: flex;
    
}

.public-input-container {
    position: relative;
    // margin: 0px 20px;
    flex-direction: column;
    margin: 0;
    padding: 0;
    width: 100%;
    
}

.public-input-container .form-icon-left {
    color: black;
    position: absolute;
    top: 12.5px;
    left: 10px;
}

.form-icon-eye {
    color: #00534C;
    margin-left: 16px;
    cursor: pointer;
    // position: absolute;
    // top: 12.5px;
    // right: 0px;

    &:hover {
        color: #8CB181;

    }
}

.password-input {
    width: 100%;
    // margin: 10px 10px 5px 10px;
    margin: 0;
    color: #000;
    font-size: 16px;
    padding: 10px 10px 10px 40px;
    border: 1.5px solid gray;
    border-radius:  6px;
    background: transparent;
    font-family: 'Titillium Web', sans-serif;
}

.hover-underlined {
    
    cursor: pointer;

    &:hover {
        color: #00534C;
    text-decoration: underline;
    }
}