/* 10-02-23 Arul: Created the Variable scss file  globally  */
/* ---------- Hinckley Medical's Theme Colors ---------- */
$hm_green : #00534C;
$hm_green_light : #016B62;
$hm_green_dark : #00372F;
$hm_green_background : #E0EADD;

$hm_green_accent : #8CB181;
$hm_green_accent_light : #B7CFB0;
$hm_green_accent_dark : #5A8F5C;

$hm_light_green : #E0EADD;

$app_background: #f8f9f9;
$grey : #9e9e9e;
$light_grey : #e0e0e0;
$line_grey: #dee2e6;
$medium_dark_grey: #808080;
$dark_grey : #616161;
$medium_grey : #f2f2f2;
$background_grey : #eaeded;
$background_dark_grey: #cdcfcf;

$red : #FA1212;
$light_red : #FF4C4C;
$dark_red : #880808;
$median_red : #8c1010;

$green : #7FFF00;
$light_green : #D0FFB3;
$cherry_green : #D0FEB3;
$dark_green : #037F02;
$notification_grid_color : #c4e6b3;

$blue : #0479b3;
$light_blue : #61add7;
$dark_blue : #7bbde0;

$white : #FFF;
$off_white : #F3F3F3;
$black : #000;
$off_black : #3A3737;

$radio_button_blue : #655393;
$radio_btn_transparent : transparent;

$light_orange : #fed698;
$light_yellow : #e9f542;
$highlight_light_yellow : #fcf403;

/* ---------- Pateint Theme Colors ---------- */
/*Primary Color*/
$patient_grey : #787878;
$patient_pink : #F9B7B2;
$patient_red : #DC143C;
$patient_purple : #823896;
$patient_yellow : #F0D600;
$patient_white : #FCFBF4;
$patient_blue : #729CC3;
$patient_orange : #FA7A45;
$patient_green : #5F9E2C;
$patient_small_adult : #8CB181;
$patient_adult : #8CB181;
$patient_large_adult : #8CB181;

/*Primary Selected Color*/
$patient_grey_selected : #787878;
$patient_pink_selected : #F9B7B2;
$patient_red_selected : #DC143C;
$patient_purple_selected : #823896;
$patient_yellow_selected : #F0D600;
$patient_white_selected : #FCFBF4;
$patient_blue_selected : #729CC3;
$patient_orange_selected : #FA7A45;
$patient_green_selected : #5F9E2C;
$patient_small_adult_selected : #8CB181;
$patient_adult_selected : #8CB181;
$patient_large_adult_selected : #8CB181;

/*Secondary Color*/
$patient_grey_light : #C8C8C8;
$patient_pink_light : #fbd2d0;
$patient_red_light : #FFC7CE;
$patient_purple_light : #BCA9B3;
$patient_yellow_light : #faf0b7;
$patient_white_light : #F5F5F5;
$patient_blue_light : #ADD8E6;
$patient_orange_light : #FFCF8B;
$patient_green_light : #C5CD94;
$patient_small_adult_light : #b7cfb0;
$patient_adult_light : #b7cfb0;
$patient_large_adult_light : #b7cfb0;

/*Secondary Selected Color*/
$patient_grey_light_selected : #C8C8C8;
$patient_pink_light_selected : #fbd2d0;
$patient_red_light_selected : #FFC7CE;
$patient_purple_light_selected : #BCA9B3;
$patient_yellow_light_selected : #faf0b7;
$patient_white_light_selected : #F5F5F5;
$patient_blue_light_selected : #ADD8E6;
$patient_orange_light_selected : #FFCF8B;
$patient_green_light_selected : #C5CD94;
$patient_small_adult_light_selected : #b7cfb0;
$patient_adult_light_selected : #b7cfb0;
$patient_large_adult_light_selected : #b7cfb0;

/*Background Color*/
$patient_grey_bg : #CCCCCC;
$patient_pink_bg : #FDE4E2;
$patient_red_bg : #F2A7B6;
$patient_purple_bg : #D0B4D8;
$patient_yellow_bg : #F9F09F;
$patient_white_bg : #DDDCD6;
$patient_blue_bg : #CADAE9;
$patient_orange_bg : #FDCDB9;
$patient_green_bg : #C3DBB0;
$patient_small_adult_bg : #B7CFB0;
$patient_adult_bg : #B7CFB0;
$patient_large_adult_bg : #B7CFB0;

/*Secondary Background Color*/
$patient_grey_bg_secondary : #CCCCCC;
$patient_white_bg_secondary : #F5F5F5;

/*Contrast Color*/
$patient_orange_contrast : #FFD699;
$patient_blue_contrast : #ADD8E6;

/*Secondary Selected Background Color*/
$patient_grey_bg_light : #a0a0a0;
$patient_pink_bg_light : #caa8a7;
$patient_red_bg_light : #cda0a5;
$patient_purple_bg_light : #97888f;
$patient_yellow_bg_light : #c8c092;
$patient_white_bg_light : #c4c4c4;
$patient_blue_bg_light : #8badb7;
$patient_orange_bg_light : #cba671;
$patient_green_bg_light : #9ea478;
$patient_small_adult_bg_light : #D28D98;
$patient_adult_bg_light : #D8B3A4;
$patient_large_adult_bg_light : #D6AF82;
/*Bluetooth Animaion Wav*/
$bluetooth_Dark_Blue_Primay : #5aabd6;
$bluetooth_Dark_Blue_Secondary : #8bc8e6;
$bluetooth_Dark_Blue_Third : #83c2e8;
$bluetooth_Dark_Blue_Four : #75b9de;

/* Progress Status */
$color_draft: #D3D3D3;
$color_draft_text: #3d3d3d;
$color_draft_hover: #C0C0C0;
$color_active: #C3DBB0;
$color_active_text: #037F02;
$color_active_hover: #B7CFB0;
$color_archive: #ADD8E6;
$color_archive_text: #0d4d8e;
$color_archive_hover: #8badb7;
$color_deleted: #FFC7CE;
$color_deleted_text: #DC143C;

/*Font Size*/
$font-family: 'Titillium Web', sans-serif;

$font_x_small : 8px;
$font_small : 10px;
$font_medium : 12px;
$font_13: 14px;
$font_medium_large : 15px;
$font_15: 15px;
$font_large : 16px;
$font_x_large : 18px;
$font_19 : 19px;
$font-20: 20px;
$font_title : 28px;
$font_weight_small : 38px;
$font_weight_large : 58px;

/*Font Weight*/
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 500 !default;
$font-weight-semibold: 600 !default;
$font-weight-bolder: 700 !default;

/*Padding*/
$paddingSet_default: 0px;
$paddingSet_x_small: 2px;
$paddingSet_small: 4px;
$paddingSet_small_medium: 6px;
$paddingSet_medium_large: 15px;

$padding_1: 1px;
$padding_x_small: 2px;
$padding_small: 3px;
$padding_small_medium: 5px;
$padding_medium: 6px;
$padding_7: 7px;
$padding_8: 8px;
$padding_medium_large: 10px;
$padding_11: 11px;
$padding_large: 14px;
$padding_16: 16px;
$padding_x_large: 18px;
$padding_xl_large: 20px;
$padding_xxl_large: 24px;
$padding_30: 30px;
$padding_31: 31px;

$padding_vertical_half: 8px;
$padding_vertical: 16px;
$padding_vertical_double: 32px;
$padding_horizontal_half: 3px;
$padding_horizontal: 6px;
$padding_horizontal_double: 12px;

/*Margin*/
$marginSet_x_small: 1px;
$marginSet_small: 2px;
$marginX_small: 3px;
$marginSmall: 5px;
$margin_6: 6px;
$marginMedium: 8px;
$margin_-10: -10px;
$marginMedium_large: 10px;
$marginMedium_double: 15px;
$marginLarge: 20px;
$marginLarge_medium: 23px;
$marginLarge_double: 25px;
$margin_30: 30px;
$margin_32: 32px;
$margin_45: 45px;
$margin_50: 50px;
$margin_58: 58px;
$margin_65: 65px;
$margin_100: 100px;
$margin_240: 240px;

/*Border*/
$borderWidth_1: 1px;
$borderWidth_x_small: 1px;
$borderWidth_small: 2px;
$borderWidth_medium: 3px;
$border_radius_4: 4px;
$borderRadius_x_small: 5px;
$borderRadius_x_medium: 8px;
$borderRadius_small: 10px;
$borderRadius_medium: 20px;
$borderRadius_medium_large: 50px;
$borderRadius_medium_double: 100px;
$borderRadius_x_large: 200px;

/*Width*/
$width_300: 300px;
$width_100: 100px;
$width_120: 120px;
$width_90: 90px;
$width_10: 10px;
$width_12: 12px;
$width_13: 13px;
$width_15: 15px;
$width_17: 17px;
$width_20: 20px;
$width_23: 23px;
$width_25: 25px;
$width_30: 30px;
$width_35: 35px;
$width_45: 45px;
$width_50: 50px;
$width_55: 55px;
$width_110: 110px;
$width_200: 200px;
$width_250: 250px;

/*Height*/
$Height_1: 1px;
$Height_10: 10px;
$Height_12: 12px;
$Height_13: 13px;
$Height_15: 15px;
$Height_17: 17px;
$Height_20: 20px;
$Height_23: 23px;
$Height_25: 25px;
$Height_30: 30px;
$Height_33: 33px;
$Height_35: 35px;
$Height_40: 40px;
$Height_50: 50px;
$Height_56: 56px;