// Author: Guruprasad Venkatraman (01-05-2024)
// Created all the class styles for the snapshot page that are neccessary for the snapshot page 
// to render correctly according to the UI designed in the Miro.

.snapshotListContainer {
    border-radius: $borderRadius_small;
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 1);
    border: $borderWidth_x_small solid $patient_grey;
    overflow: hidden; 
}

.snapshotItem {
    display: flex;
    align-items: center;
    border-bottom: $borderWidth_small solid $line_grey;
    background-color: $white;
    &:last-child {
        border-bottom: none;
    }
    overflow: hidden;
}

.protocolVitalIcon {
    width: $width_50; 
    height: $Height_50;
    margin: $marginMedium;
}

.imageNameContainer {
    display: flex;
    justify-content: flex-start;
    flex-grow: 1;
    align-items: center;
}

.a1Content {
    display: flex;
    // align-items: flex-start;
    flex-direction: row;
    flex-grow: 1;
    font-size: $font-weight-normal;
    margin-right: $marginMedium_large;
}

.contentTitle{
    font-size: $font_x_large !important;
    padding: 0px !important;
    margin-right: $marginMedium_large;
    text-align: left;
    color: $black;
}

.a1SubItemsContent {
    font-size: $font_medium_large !important;
    padding: 0px !important;
    margin-right: $marginMedium_large;
    text-align: left;
}

.dataContentText {
    font-size: $font_large !important;
    font-weight: $font-weight-semibold !important;
    margin-right: $margin_65;
    font-family: $font-family;
}

.forwardIcon {
    margin-left: auto;
}

.dateChangesContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 0;
}

