/* 10-03-23 Arul: Created the  seperate scssfile for Accordion  */

.info {
    .p-accordion-header-link>svg {
        transform: rotate(90deg);
    }

    .p-highlight .p-accordion-header-link>svg {
        transform: rotate(180deg);
    }

    .accordion-container {
        border-radius: $borderRadius_x_small;
    }

    .accordion-container-border {
        border: $borderWidth_1 solid $light_grey;
        border-radius: $borderRadius_x_small;
    }

    .p-accordion .p-accordion-header .p-accordion-header-link {
        display: flex;
        align-items: center;
        justify-items: center;
        width: auto;
        background: none;
    }

    .p-accordion .p-accordion-content {
        box-sizing: content-box;
        padding: 0px;
        border-left: 0px !important;
        border-right: 0px !important;
        align-items: center;
        justify-items: center;
    }

    .p-accordion-header-text {
        display: flex;
        border-left: 0px !important;
        border-right: 0px !important;
        border-radius: 0px !important;
        align-items: center;
        justify-items: center;
        width: auto
    }

    .p-accordion .p-accordion-header .p-accordion-header-link .p-header-text{
        width: auto;
    }

    .p-highlight {
        background-color: #f2f2f2;
        // color: $white;
        // box-shadow: none;
        border-radius: $borderRadius_x_medium;
    }

    .p-highlight a {
        color: $white;
    }

    .accordion_line {
        margin: $marginSmall 0px
    }
}