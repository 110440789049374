/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;9006display=swap'); */
@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

@import 'bootstrap/dist/css/bootstrap.min.css';

/* PrimeReact CSS */
@import '~primereact/resources/primereact.min.css';
@import '~primereact/resources/themes/bootstrap4-light-blue/theme.css';

@import './assets/scss/app.scss';

.App {
  display: flex;
  flex: 1;
}

.text {
  font-size: 16px;
  color: #000000;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 600;
}

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Titillium Web', sans-serif;
  color: #000000;
}

.hoverItem {
  &:hover {
    cursor: pointer;
    background-color: $hm_green_background;
  }
}

.hoverItemRound {
  &:hover {
    cursor: pointer;
    background-color: $hm_green_background;
    border-radius: $borderRadius_x_medium;
  }
}

header {
  padding: 10px;
  height: 40px;
  box-sizing: content-box;
}

header img {
  width: 180.5px;
  // width: auto;
  // aspect-ratio: 1;
  height: 40px;
  margin-left: 15px;
}

.sidebar-ul {
  padding: 0;
  margin: 0;
}

.sidebar-footer {
  position: absolute;
  // display: flex;
  // flex-direction: column;
  bottom: 0;
  flex: 1;
  width: calc(100% - 30px);
  // margin: 0px 0px 0px -15px;
  margin: 0;
  padding: 0;
  color: #eaecef;
  font-size: 14px;
  text-align: center;
}

.signup {
  float: right;
  border: none;
  padding: 10px 17px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
}

.signup:hover {
  background-color: $hm_green_light;
  /* Green */
  color: white;
}

.cursorPointerLabel label {
  cursor: pointer !important;
}

.cursorPointerInput input {
  cursor: pointer !important;
}

.cursorPointerSpan span {
  cursor: pointer !important;
}

.cursorPointerDiv div {
  cursor: pointer !important;
}

.cursorArrow {
  cursor: context-menu !important;
}

.left-sidebar-container {
  position: fixed;
  top: 62px;
  left: 0;
  display: flex;
  flex-direction: row;
  z-index: 1000;
  transition: all 0.4s;
}

.activity-log-sidebar {
  width: 300px;
  height: calc(100vh - 102px);
  
  background: #f8f9f9;
  top: 62px;
  margin: 0 0 0 10px;
  /* Top, right, bottom, left */
  padding: 15px 15px 15px 15px;
  border-radius: 12px;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  position: fixed;
  // left: 80px; // Positioned right next to the mini sidebar
  left: -100%;
  transition: left 0.4s ease; // Smooth slide effect for left property
  // Adding the shadow effect
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  &.active {
    // right: 10px;
    left: 80px;
  }

  .activity-log-content {
    flex: 1;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #e0eadd #f8f9f9;
  }

  .sidebar-ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      margin: 10px 0;

      a {
        color: #eaecef;
        text-decoration: none;
        font-size: 16px;
        display: block;
        padding: 10px 15px;
        border-radius: 6px;
        font-family: 'Titillium Web', sans-serif;

        &:hover {
          background: #e0eadd;
        }
      }
    }
  }

  // Set icon color for ActivityLogSidebar
  .icon {
    color: $dark_grey;
    font-size: 18px;
    margin-right: 10px;
  }

  .titleItemContainer {
    display: flex;
    align-items: center;
  }

  .titleItemText {
    font-weight: 500;
    margin: 0;
    font-family: 'Titillium Web', sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .greeting {
    font-family: 'Titillium Web', sans-serif;
    color: black;
    margin-bottom: 15px;
    
    span {
      display: inline-flex;  // Better alignment with icon
      align-items: center;
    }
  }
}

.sidebar {
  width: 200px;
  height: calc(100vh - 102px);

  background: #00534c;
  position: fixed;
  top: 62px;
  margin: 0 0 0px 10px;
  /* Top, right, bottom, left */
  padding: 15px 15px 15px 15px;
  left: -100%;
  transition: all 0.4s;
  border-radius: 12px;
  box-sizing: content-box;
  z-index: 1000;

  &.mini {
    width: 50px;
    padding: 15px 10px;

    .avatar-container {
      margin-bottom: 20px;
      height: 45px; // Match the height of greeting
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.4s ease;
    }

    .avatar-mini {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #003935;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-weight: 600;
      font-family: 'Titillium Web', sans-serif;
    }

    .sidebar-footer {
      display: none;
    }

    .sidebar-ul {
      li {
        list-style-type: none;
        margin: 10px 0;

        div {
          width: 100%;
        }

        a {
          color: #eaecef;
          text-decoration: none;
          font-size: 16px;
          display: flex;
          padding: 10px 15px;
          border-radius: 6px;
          font-family: 'Titillium Web', sans-serif;
          justify-content: center;
          align-items: center;
          transition: background-color 0.3s ease;

          svg {
            margin: 0;
            font-size: 18px;
            width: 18px;
            height: 18px;
          }

          span {
            display: none;
          }

          &:hover,
          &:active {
            background: #003935;
          }
        }

        // Match exactly with non-mini selected state style
        div[style*="backgroundColor: #003935"] a,
        div[style*="backgroundColor: rgb(0, 57, 53)"] a {
          background-color: #003935;
          border-radius: 6px;
        }

        // Tooltip styles
        a {
          position: relative;

          &:hover::after {
            content: attr(title);
            position: absolute;
            left: calc(100% + 10px);
            top: 50%;
            transform: translateY(-50%);
            background: #003935;
            color: #fff;
            padding: 8px 12px;
            border-radius: 6px;
            font-size: 14px;
            white-space: nowrap;
            z-index: 1001;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
          }
        }
      }
    }
  }
}

.sidebar.active {
  left: 0;
}

.logo {
  width: 60px;
}

.sidebar ul {
  padding: 0;
}

.sidebar ul li {
  list-style-type: none;
  margin: 10px 0;
}

.sidebar ul li a {
  color: #eaecef;
  text-decoration: none;
  font-size: 16px;
  display: block;
  padding: 10px 15px;
  border-radius: 6px;
  font-family: 'Titillium Web', sans-serif;
}

.sidebar ul li a:hover,
.sidebar ul li a:active {
  background: #003935;
}

.sidebar ul li a svg {
  margin-right: 10px;
}

header svg {
  color: #000000;
  font-size: 40px;
  font-family: 'Titillium Web', sans-serif;
}

.main {
  position: initial;
  min-height: calc(100vh - 78px);
  display: flex;
  flex-direction: column;
  box-sizing: content-box;
  min-width: 30vw;
  flex: 1;
  // overflow-x:scroll;

  margin: 62px 10px 10px 10px;
  padding: 0 0 0px 0;
  border: 2px solid white;
  border-radius: 10px;

  background-color: white;
  font-size: 40px;
  color: #000000;
  box-shadow: 0 0 3px #9e9e9e;
  scrollbar-shadow-color: #00534c;
  transition: all 0.5s;
  font-family: 'Titillium Web', sans-serif;

  &.with-sidebar {
    margin: 62px 10px 10px 250px;
    transition: all 0.5s;

    &.with-activity-log {
      margin-left: 430px;
    }
    
  }
}

.public-main {
  position: initial;
  // min-height: 92.2vh;
  display: flex;
  flex-direction: column;
  box-sizing: content-box;

  // margin: 62px 0px 0px 0px;
  margin: 0px 10px 10px 10px;
  padding: 0 0 0px 0;
  border: 2px solid white;
  border-radius: 10px;

  background-color: white;
  font-size: 40px;
  color: #000000;
  box-shadow: 0 0 3px #9e9e9e;
  scrollbar-shadow-color: #00534c;
  transition: all 0.5s;
  font-family: 'Titillium Web', sans-serif;
  flex: 8;

  &.with-sidebar {
    // margin: 62px 10px 10px 23vw;
    transition: all 0.5s;
  }
}

.sidebar.active .greeting {
  font-family: 'Titillium Web', sans-serif;
  color: #fff;
  font-size: 28px;
  opacity: 1;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  transition: all 0.4s ease;
}

.sidebar .greeting {
  font-family: $font-family;
  color: #fff;
  font-size: 28px;
  opacity: 1;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  transition: all 0.4s ease;
}

/* account drop downs */
.accountbar {
  height: 40px;
  width: 200px;
  background-color: '#fff';
  padding: 0 1rem;
  margin: 0;
  float: right;
}

.accountbar-nav {
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
}

.drop-item {
  width: calc(60px * 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.workbookHeader {
  margin: 0;
  display: flex;
  font-size: large;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
}

.icon-button {
  --button-size: calc(60px * 0.5);
  width: var(--button-size);
  height: var(--button-size);
  background-color: #f8f9f9;
  border-radius: 50%;
  padding: $padding_small_medium;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 $marginMedium_large 0 0;
  /* Top, right, bottom, left */

  :hover {
    cursor: pointer;
  }
}

.icon-button svg {
  fill: #00534c;
  width: $Height_20;
  height: $width_20;
}

.dropdown {
  position: absolute;
  top: 50px;
  width: 150px;
  transform: translateX(-45%);
  background-color: #00534c;
  border: $borderWidth_x_small solid #474a4d;
  border-radius: $borderRadius_x_medium;
  padding: 0;
  overflow: hidden;
  z-index: 40;
}

.menu-item {
  height: $Height_50;
  display: flex;
  align-items: center;
  border-radius: $borderRadius_x_medium;
  padding: 0.5rem;
  color: #f8f9f9;
}

.menu-item:hover {
  background-color: #003935;
}

.workbook-upload-container {
  display: flex;
  align-items: center;
  flex: 1;
}

.icon-right {
  margin-left: auto;
}

a {
  color: #f8f9f9;
  text-decoration: none;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal styles */
.modal {
  background-color: #fff;
  padding: $padding_xl_large;
  border-radius: $borderRadius_x_medium;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.modal_container {
  top: '50%';
  left: '50%';
  right: 'auto';
  bottom: 'auto';
  margin-right: '-50%';
  transform: 'translate(-50%, -50%)';
}

.screen-container {
  display: flex;
  flex-direction: column;
  margin: $marginLarge;

  // overflow-y: auto;
  flex: 1;
  // min-height: 87vh;
  transition: all 0.5s;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
  @media (min-width: 768px) {
    .grid-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 767px) {
    .grid-container {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.grid-item {
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 20px;

  &:hover {
    cursor: pointer;
    background-color: $hm_green_background;
  }

  &.disabled {
    opacity: 0.5;
    // pointer-events: none;
  }

  svg {
    color: $dark_grey;
  }
}

.item-name {
  font-weight: 600;
  font-size: large;
  margin-bottom: 15px;
}

.item-count {
  margin-top: 15px;
  font-size: medium;
  text-align: center;
}

.search-results {
  padding: 5px;
}

.category-results h3 {
  margin-top: 20px;
  font-size: large;
  color: $hm_green;
}

.category-results ul {
  list-style-type: none;
  font-size: medium;
  padding: 0;
}

.category-results li {
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}

.grid-item:hover {
  cursor: pointer;
  background-color: $hm_green_background;
}

.category-results li:hover {
  cursor: pointer;
  background-color: $hm_green_background;
}

.p-button-success {
  background-color: $hm_green_background;
  font-size: medium;
  color: black;
  border-radius: 5px;
}

.p-button-success:hover {
  cursor: pointer;
  background-color: $hm_green;
  color: white;
}

.list-view-container {
  padding: 5px;
  margin-top: 10px;
  background-color: #fff;
  z-index: 10;
}

.list-view-category {
  border-bottom: 1px solid #eee;
  color: $hm_green;
}

.list-view-item:hover {
  cursor: pointer;
  background-color: $hm_green_background;
}

.list-view-category h3 {
  color: #333;
  padding: 10px 0;
}

.list-view-item {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 8px 6px;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.item-details {
  display: flex;
  align-items: center;
}

.item-details span {
  font-size: 16px;
  color: #616161;
}

.status {
  font-weight: bold;
  padding: 2px 5px;
  border-radius: 4px;
}

.status.active {
  color: $hm_green;
}

.status.archive {
  color: $red;
}

.modern-button {
  padding: 10px 20px;
  margin-right: 10px;
  border: none;
  background-color: $hm_green_background;
  color: black;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

.modern-button:hover {
  background-color: $hm_green;
  color: white;
}

/* red_background */
.red_backGround {
  background-color: $median_red;
}

/* white_background */
.white_backGround {
  background-color: $white;
}

/* green_background */
.green_backGround {
  background-color: $hm_green;
}

/* white text color */
.white_text {
  color: $white;
}

/* green text color */
.green_text {
  color: $hm_green;
}

/* black text color */
.black_text {
  color: $black;
}

.green_accent_text {
  color: $hm_green_accent;
}

/* grey text color */
.grey_text {
  color: $grey;
}

.light_grey_text {
  color: $light_grey;
}

/* grey text color */
.red_text {
  color: $median_red;
}

.ReactModalPortal {
  position: absolute;
  z-index: 999;
}

.colAlignCenter {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}

.colAlignEnd {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;

  &.fullScreenPdfBtn {
    position: absolute !important;
    right: 55px !important;
  }
}

.contentTitle {
  font-size: $font_large;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 600;
  padding: $padding_small_medium;
  align-items: center;
  display: flex;

  &.dialogTitle {
    font-size: $font-20;
  }
}

.contentTitleLarge {
  font-size: $font_title;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 600;
  padding: $padding_small_medium;
  align-items: center;
  display: flex;
  color: $black;

  &.dialogTitle {
    font-size: $font-20;
  }
}

.contentLabel {
  font-size: $font_19;
  font-family: 'Titillium Web', sans-serif;
  align-items: center;
  display: flex;
  font-weight: 400;
  margin-top: $marginMedium_double;
  margin-bottom: $marginSmall;
  color: $black;
}
.contentLabelBold {
  font-size: $font_19;
  font-family: 'Titillium Web', sans-serif;
  align-items: center;
  display: flex;
  font-weight: 600;
  margin-top: $marginMedium_double;
  margin-bottom: $marginSmall;
  color: $black;
}

.reviewcontentLabel {
  font-size: $font_19;
  font-family: 'Titillium Web', sans-serif;
  align-items: center;
  display: flex;
  font-weight: 600;
  margin-top: $marginMedium_double;
  margin-bottom: $marginSmall;
}

.contentHeight {
  height: $Height_50;
}

.hoverErrorText {
  color: '#000';
  cursor: pointer;

  &:hover {
    color: '#880808';
  }
  // &:active {
  //     color: '#880808';
  // }
}

.contentHeading {
  font-size: $font_13;
  font-family: $font-family;
  align-items: center;
  display: flex;
  font-weight: 500;
  padding: $padding_medium_large;
  color: $black;

  &.newProtocolBorder {
    padding: $padding_small_medium;
    color: $hm_green_accent;
    justify-content: center;
  }

  &.checklist {
    font-size: 10px;
  }
}

// .editIconContainer {
//   // position: absolute;
//   // right: 50px;
// }

.editIconContainer:hover {
  background-color: $white;
  border-radius: 25px;
}

.contentHeadingBold {
  @extend .contentHeading;
  font-weight: 700;
}

.contentText {
  font-size: 14px;
  font-family: $font-family;
  align-items: center;
  display: flex;

  &.contentTextBlackColor {
    color: $black;
  }

  &.greyText {
    color: $dark_grey;
  }
}

.errorText {
  font-size: $font_13;
  font-family: $font-family;
  align-items: center;
  display: flex;
  color: $dark_red;
}

.contentBorder {
  border: $borderWidth_1 solid $light_grey;
  border-radius: $borderRadius_x_small;
  background-color: $white;
  z-index: 100;

  &.protocolCalculationPad {
    border-radius: $borderRadius_x_small;
  }

  &.primaryListScroll {
    overflow-y: auto;
    max-height: 75%;
  }

  &.secondaryListScroll {
    overflow-y: auto;
    max-height: 65%;
  }
}

.roundBorder {
  border: $borderWidth_1 solid $light_grey;
  border-radius: $borderRadius_x_small;
}

.workbookContentBorder {
  border: $borderWidth_1 solid $light_grey;
  border-radius: $borderRadius_x_small;
  background-color: $white;
  z-index: 100;

  &.primaryListScroll {
    overflow-y: auto;
    max-height: 75%;
  }

  &.secondaryListScroll {
    overflow-y: auto;
    max-height: 65%;
  }
}

.borderForCustomPDFWorkbook {
  border: $borderWidth_1 solid $light_grey;
  border-radius: $borderRadius_x_small;
  background-color: $white;
}

.reviewListScroll {
  overflow-y: auto;
  max-height: 75%;
}

.contentUnderline {
  border-bottom: $borderWidth_1 solid $light_grey;
  margin: 0 !important;
}

.fixedHeader {
  position: sticky;
  top: 54px;
  z-index: 10;
  background: white;
}

.cursorPointer {
  cursor: pointer;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
}

.headerTilte {
  margin-left: -$marginMedium;
}

.sidebarContainer {
  margin: $marginLarge;
  overflow-y: auto;
  display: flex;
  height: 100%;
  flex-direction: column;
}

.sidebarPdfContainer {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.dropPdfContainer {
  height: 90%;
  width: 75%;
  background: 'transparent';
}

.required-field {
  color: $red;
  margin-left: $marginSmall;
  font-weight: bold;
  align-items: center;
  display: flex;
  // margin-top: $marginX_small;
  height: $Height_10;
  display: flex !important;
  justify-content: center !important;
  align-items: flex-start !important;
}

.display_flex {
  display: flex;
}
.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  background-color: $hm_green;
  color: $hm_green;
  border-color: $hm_green;
}
.p-radiobutton .p-radiobutton-box  {
  background-color: #E0EADD;
  // color: $hm_green;
  // padding: 1px;
  border-color: $hm_green;
  border-width: 1.75px;
}
.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: $hm_green;
  border-width: 1.5px;
  background: $white;
}

.p-radiobutton .p-radiobutton-box {
  width: 16px;
  height: 16px;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  color: $hm_green !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: $hm_green !important;
  background: $hm_green_background !important;
  width: 16px;
  height: 16px;
}

.p-checkbox .p-checkbox-box {
  width: 16px;
  height: 16px;
}

.radioBtnColor {
  .p-highlight {
    background-color: $white !important;
    color: $hm_green !important;
  }
}

.titleItemContainer {
  display: flex;
  align-items: center; /* Adjust based on your design */
  // flex-wrap: wrap; /* Allows items to wrap to the next line */
}

.no-select {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera, and Firefox */
}

.titleItemText {
  font-weight: 500; /* No quotes needed for numeric values */
  margin: 0 0 0px 0;
  align-items: center;
  font-family: 'Titillium Web', sans-serif;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.protocolDatatableText {
  font-weight: 500; /* No quotes needed for numeric values */
  margin: 0 6px;
  align-items: center;
  font-family: 'Titillium Web', sans-serif;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.hoverableText {
  display: inline; /* Make text inline or inline-block */
  flex-direction: row; /* This is not necessary for inline elements */
  cursor: pointer;
  color: $black;
  &:hover {
    color: $hm_green;
    //Underline
    text-decoration: underline;
  }

  &.disabled {
    color: #636363;

    &:hover {
      color: $hm_green;
    }
  }
}

.searchBox {
  width: 100%;
  padding: 0px;
  margin: 0px;
  border: $borderWidth_1 solid $light_grey;
  border-radius: $borderRadius_x_small;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-items: center;

  &:focus {
    outline: $borderWidth_1 solid $hm_green;
  }

  &.searchHoverable {
    &:hover {
      border: $borderWidth_1 solid $hm_green;
    }
  }
}

.searchNoStyle {
  width: 100%;
  padding: 6px 8px;
  border: 0px solid $light_grey;
  border-radius: $borderRadius_x_small;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:focus {
    outline: 0px solid $hm_green;
  }

  &.searchHoverable {
    &:hover {
      border: 0px solid $hm_green;
    }
  }
}

.clickableText {
  /* underline the text*/
  text-decoration: underline;
  color: $hm_green_accent;
  margin-left: 10px;
  &:hover {
    color: $hm_green;
    cursor: pointer;
  }
}

.times-icon-container {
  padding: 6px;
  color: $red;
  .times-icon {
    font-size: 20px;
    cursor: pointer;
  }
  &:hover {
    color: $white;
    border-radius: 6px;
    background-color: $dark_red;
  }
}

.search-item {
  @extend .text;
  padding: 5px;
  cursor: pointer;
  gap: 8px;
  display: flex;
  align-items: center;
  font-size: medium;
  font-weight: 400;

  &:hover {
    background-color: $hm_green_background;
  }

  &.bottom-border {
    border-bottom: 1px solid $light_grey;
  }

  &.focused {
    border: 1px solid $hm_green;
    border-radius: 6px;
    box-shadow: 0 0 2px $hm_green;
  }
}

.drag-drop-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.owner-logo {
  width: 40px;
  height: auto;
  aspect-ratio: 1;
  border-radius: 50%;
}

.hm-checkbox {
  width: 18px;
  height: 18px;
  background-color: #fff;
  border: 1px solid #00534C;
  border-radius: 9px;
  padding: 4px;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
  border-color: #00534C !important;

  &.selected {
    background-color: #00534C;
  }

  // &.selected:hover {
  //   background-color: #00534C;
  //   color: #00534C;
  //   box-shadow: 0 0 10px #9e9e9e;
  // }

  &:hover {
    background-color: #00534C !important;
    box-shadow: 0 0 10px #B7CFB0;
    color: #00534C !important;
  }

  &:focus,
  &:active {
    outline: none; /* Remove browser-specific outline on click or focus */
    background-color: #00534C; /* Ensure it's always green */
    box-shadow: none; /* Avoid default focus/active shadows */
  }


}

.privacy-policy-header {
  font-size: 16px;
  font-family: $font-family;
  align-items: center;
  display: flex;
  font-weight: 600;
}

.privacy-policy-text {
  font-size: 16px;
  font-family: $font-family;
  align-items: center;
  display: flex;
  margin-left: 1rem;
}

.privacy-policy-list-item {
  font-size: 16px;
  font-family: $font-family;
  // align-items: center;
  display: flex;
  margin-left: 2rem;
  //Add bullet point and align it to the top left
  &::before {
    content: '\2022';
    margin-right: 0.5rem;
  }
}

.new-badge {
  background-color: #ADD8E6;
  color: #0d4d8e;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  margin-left:1rem;

  &.hoverable {
    cursor: pointer;
    &:hover {
      background-color: #8badb7;
    }
  }
}
