.large-dep-logo {
  width: 140px;
  height: auto;
  margin: 0;
  padding: 0;
  // border-radius: 50%;
  // object-fit: cover;
  aspect-ratio: 1;
  // background-color: #f8f9f9;
}

.large-dep-name {
  color: black;
  font-weight: bold;
  font-family: 'Titillium Web', sans-serif;
  display: flex;
  align-items: flex-end;
  font-size: 48px !important;
  font-weight: 600;
  margin-top: 2px;
  padding: 0;
}