.fixedNavbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: #f8f9f9;
  height: 54px;
  width: 100vw;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.navBarContainer {
  display: flex;
  padding: 0;
  margin: 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.logo-container-left {
  display: flex;
  align-items: center;
  flex-direction: row;
  // margin-right: 20px;
  gap: 6px;
}

.logo-container-right {
  display: flex;
  align-items: center;
  flex-direction: row;
  // margin-right: 20px;

  .notification-container {
    position: relative;
    margin-right: 20px;
  }

  .notification-icon {
    position: relative;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #8cb181;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .notification-icon svg {
    color: white;
    height: 20px;
  }

  .notification-badge {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: red; /* Badge background color */
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }

  .notification-list {
    padding: 20px;
    position: absolute;
    top: 110%;
    right: 0;
    width: 300px;
    border-radius: 5px;
    background: white;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    max-height: 300px;
    overflow-y: auto;
    cursor: pointer;
  }
  .notification-item:hover {
    background-color: #8CB181;
    border: #8CB181;
    color: white;
  }
  .notification-header {
    font-weight: 600;
    font-size: 20px;
  }
  .notification-item {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }

  .notification-item:last-child {
    border-bottom: none;
  }
}

.drop-item {
  // width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-item {
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 0.5rem;
  color: #f8f9f9;
  cursor: pointer;
}

.menu-item:hover {
  background-color: #003935;
}

.icon-button {
  // --button-size: 40px;
  // width: var(--button-size);
  // height: var(--button-size);
  // background-color: #f8f9f9;
  // border-radius: 50%;
  // padding: 6px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // cursor: pointer;
  // margin-right: 6px;
  /* Top, right, bottom, left */

  // :hover {
  //     transform: scale(1.1);
  // }
}

.icon-button svg {
  fill: #00534c;
  width: 24px;
  height: 24px;
}

.menu-bars {
  margin-left: 24px;
  margin-right: 10px;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
}

.c-dropdown_list {
  position: absolute;
  top: 100%;
  right: 20px;
  width: auto;
  box-sizing: border-box;
  list-style: none;
  padding-left: 0px;
  border: 1px solid #919191;
  border-radius: 6px;
  padding: 5px 0px;
  color: #000;
  background-color: white;
  //Add shadow
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.c_dropdown_option {
  display: flex;
  align-items: center;
  margin: 0px 4px;
  padding: 6px 10px;
  cursor: pointer;
  font-size: 14px;
  font-family: 'Titillium Web', sans-serif;
  border-radius: 6px;
  &:hover {
    background-color: #e0eadd;
  }
}

.hover-raise-elevation {
  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  transition: box-shadow 0.3s;
}

.border {
  display: flex;
  align-items: center;
  flex-direction: row;
  border: 1px solid #00534c;
  border-radius: 6px;
  padding: 0.25rem;
  color: #00534c;
  cursor: pointer;
  margin-right: 16px;
}

.department-logo {
  width: 35px;
  height: auto;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  // object-fit: cover;
  aspect-ratio: 1;
  background-color: #f8f9f9;
}

.departmentName {
  color: black;
  font-weight: 600;
  font-family: 'Titillium Web', sans-serif;
  display: flex;
  align-items: flex-end;
  font-size: 16px !important;
  font-weight: 600;
  margin-top: 2px;
  padding: 0;
}

.loadingText {
  color: #00534c;
  font-size: 18px;
  font-weight: 600;
  font-family: 'Titillium Web', sans-serif;
  display: flex;
  align-items: flex-end;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

.load svg {
  fill: #00534c;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

.load.light svg {
  fill: #8cb181;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

.department-menu-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 0.5rem;
  cursor: pointer;

  &:hover {
    background-color: #E0EADD;
  }

  &.disabled {
    cursor: default;
    &:hover {
      background-color: transparent ;
    }
  }
}
