
.fixedNavbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background: #f8f9f9;
    height: 54px;
    width: 100vw;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.navBarContainer {
    display: flex;
    padding: 0;
    margin: 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.logo-container-left {
    display: flex;
    align-items: center;
    flex-direction: row;
    // margin-right: 20px;
    gap: 6px;
}

.logo-container-right {
    display: flex;
    align-items: center;
    flex-direction: row;
    // margin-right: 20px;
}

.drop-item {
    // width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu-item {
    height: 50px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    padding: 0.5rem;
    color: #f8f9f9;
    cursor: pointer;
  }
  
  .menu-item:hover {
    background-color: #003935;
  }

.icon-button {
    // --button-size: 40px;
    // width: var(--button-size);
    // height: var(--button-size);
    // background-color: #f8f9f9;
    // border-radius: 50%;
    // padding: 6px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // cursor: pointer;
    // margin-right: 6px;
    /* Top, right, bottom, left */
  
    // :hover {
    //     transform: scale(1.1);
    // }
  }
  
  .icon-button svg {
    fill: #00534C;
    width: 24px;
    height: 24px;
  }

.menu-bars {
    margin-left: 24px;
    margin-right: 10px;
    cursor: pointer;
    &:hover {
        transform: scale(1.1);
    }
}

.c-dropdown_list {
    position: absolute;
    top: 100%;
    right: 20px;
    width: auto;
    box-sizing: border-box;
    list-style: none;
    padding-left:0px;
    border:1px solid #919191;
    border-radius: 6px;
    padding:5px 0px;
    color: #000;
    background-color: white;
    //Add shadow
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);

}

.c_dropdown_option {
    display:flex;
    align-items: center;
    margin: 0px 4px;
    padding:6px 10px;
    cursor:pointer;
    font-size: 14px;
    font-family: 'Titillium Web', sans-serif;
    border-radius: 6px;
    &:hover {
        background-color: #e0eadd;
    }
}

.hover-raise-elevation {
    &:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
        cursor: pointer;
    }
    transition: box-shadow 0.3s;
}

.border {
    display: flex;
    align-items: center;
    flex-direction: row;
    border: 1px solid #00534C;
    border-radius: 6px;
    padding: 0.25rem;
    color: #00534C;
    cursor: pointer;
    margin-right: 16px;
}

.department-logo {
    width: 35px;
    height: auto;
    margin: 0;
    padding: 0;
    border-radius: 50%;
    // object-fit: cover;
    aspect-ratio: 1;
    background-color: #f8f9f9;
}

.departmentName {
    color: black;
    font-weight: 600;
    font-family: 'Titillium Web', sans-serif;
    display: flex;
    align-items: flex-end;
    font-size: 16px !important;
    font-weight: 600;
    margin-top: 2px;
    padding: 0;
}

.loadingText {
    color: #00534C;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Titillium Web', sans-serif;
    display: flex;
    align-items: flex-end;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
}

.load svg{
    fill: #00534C;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
}

.buttonText {
    color: #000;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Titillium Web', sans-serif;
    display: flex;
    align-items: flex-end;
    margin: 0 0 0 0;
    padding: 0.25rem 0.5rem;
}

.hover-select-text{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0.25rem;
    cursor: pointer;
    border-radius: 0.5rem;
    gap: 0.5rem;

    &:hover {
        background-color: #e0eadd;
    }
}