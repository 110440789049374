.screen-container.review-details {
  .titleTextPH {
    width: 100%;
  }
}
.contentBorderLeft {
  position: relative;
  padding-left: 30px; /* space for the vertical line */
  padding-right: 20px;
  font-size: 18px;

  .itemContainer {
    position: relative;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .commitIcon {
    // display: inline-block;
    vertical-align: text-bottom;
    position: absolute;
    left: -8px;
    top: 30%;
    color: grey;
    background-color: white;
  }

  .itemList {
    list-style: none;
    padding: 0;
    margin: 0;
    display: inline-block;
    width: 100%;
    margin-left: 6px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
  }

  .createUserIcon {
    display: inline-block;
    vertical-align: text-bottom;
    position: absolute;
    left: -25px;
    top: 10%;
    // color: grey;
    // background-color: white;
  }

  .userComment {
    cursor: pointer;
  
    &:hover {
      background-color: #f7faf6;
    }
  }

  .item {
    position: relative;
    padding: 6px;
    padding-left: 8px;
    margin: 0;
    border-radius: 8px;
    background: white;
    font-size: 16px;
  }

  .commentEventSection {
    position: relative;
  }

  .commentEventSection::before {
    content: '';
    position: absolute;
    left: -10px;
    top: 0;
    bottom: 0;
    width: 2px;
    background: rgb(224, 224, 224); /* Adjust color */
    height: 100%;
  }
}

.publishButton-pending {
  display: inline-block;
  color: white;
  background-color: #8b0000;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  padding: 5px 20px 5px 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  vertical-align: top;
}

.publishButton-pending:hover {
  border-color: #8b0000;
  background-color: #8b0000;
  color: #ffebee;
  cursor: pointer;
}

.publishButton-approved {
  display: inline-block;
  color: white;
  background-color: #037f02;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  padding: 5px 20px 5px 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  vertical-align: top;
}

.publishButton-approved:hover {
  border-color: #006400;
  background-color: #006400;
  color: #e8f5e9;
  cursor: pointer;
}

.publishButton-rejected {
  display: inline-block;
  color: white;
  background-color: #8b0000;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  padding: 5px 20px 5px 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  vertical-align: top;
  
}

.publishButton-rejected.disabled {
  background-color: #eaeef2b3;
  cursor: not-allowed;
  color: #8c959f;
  border: 1px solid #eaeef2b3;
}

.publishButton-rejected:hover {
  border-color: #8b0000;
  background-color: #8b0000;
  color: #ffebee;
  cursor: pointer;
}

.publishButton-close {
  display: inline-block;
  color: white;
  background-color: #9a9b9c;
  border: 1px solid #fff;
  border-radius: 8px;
  font-size: 16px;
  padding: 5px 20px 5px 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  vertical-align: top;

  &:hover {
    border-color: #8b0000;
    background-color: #8b0000;
    color: #ffebee;
  }
}

/* Status message styles */
.statusMessage {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.statusMessage.approved {
  background-color: #d4edda;
  color: #155724;
}

.statusMessage.pending {
  background-color: #d1ecf1;
  color: #0c5460;
}

.tickIcon {
  font-size: 20px;
  margin-right: 8px;
}

.branch-icon {
  color: white;
  display: inline-block;
  width: 45px;
  height: 45px;
  border-radius: 4px;
  vertical-align: top;
}
.branch-icon.approved {
  background-color: #037f02;
}
.branch-icon.pending {
  background-color: #8b0000;
}
.mergeIcon {
  margin-left: 8px;
  margin-top: 7px;
}
.mergeContainer {
  margin-left: 20px;
  border-radius: 3px;
  display: inline-block;
  width: 60%;
  position: relative;
  background-color: white; /* Ensure the background is white */
}
.mergeContainer.pending {
  border: 1.5px solid #8b0000;
}
.mergeContainer.approved {
  border: 1.5px solid #037f02;
}
.mergeContainer::before,
.mergeContainer::after {
  content: '';
  position: absolute;
  top: 20%;
  left: -21px; /* Adjust this value to move the arrow closer or further from the div */
  width: 0;
  height: 0;
  border: 10px solid transparent;
  transform: translateY(-50%);
}

.mergeContainer.pending::before {
  border-right-color: #8b0000;
  left: -21px;
}

.mergeContainer.approved::before {
  border-right-color: #00534c;
  left: -21px;
}

.mergeContainer::after {
  border-right-color: white;
  left: -19px;
}

.mergeMessage {
  background-color: #f6f8fa;
  padding: 10px;
  border-top: 1px solid rgb(208, 215, 222);

  .pendingCheckbox {
    width: 40px;
    margin-top: 3px;
    height: 26px;
    display: inline-block;
    margin-right: 10px;
  }
  input[type='checkbox']:checked {
    accent-color: #8b0000;
  }

  .publishButton-pending.disabled {
    background-color: #eaeef2b3;
    cursor: not-allowed;
    color: #8c959f;
    border: 1px solid #eaeef2b3;
  }
}

.reviewer {
  input[type='checkbox']:checked {
    accent-color: rgb(0, 100, 0);
  }
}

.contentBorderRight {
  /* Existing styles */
}
.doseReview {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  background-color: rgb(248, 249, 250);
  border-bottom: 1px solid rgb(208, 215, 222);
  padding-left: 20px;
}

.doseName {
  font-size: 18px;
  font-weight: bold;
  // flex: 0 0 20%;
  display: flex;

  .doseNameText {
    margin-left: 10px;
  }
}

.doseReviewButtonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  flex: 1;
}

.tooltip {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 100;
  white-space: nowrap;
}

.doseReviewButton {
  padding: 5px 10px;
  border-radius: 5px;
  padding: 5px;
  width: 11%;
  font-size: 13px;
}
.doseChangesWrapper {
  width: 100%;
  overflow-x: auto;

  .changesTable {
    width: 100%;
    border-collapse: collapse;
    font-size: 18px;
    text-align: left;
    table-layout: fixed;

    thead {
      tr {
        background-color: #f4f4f4;
        text-align: center;
      }
    }

    th,
    td {
      vertical-align: top;
      width: 50%;
      padding: 12px 15px;
      border: 1px solid #ddd;
      font-size: 14px;
    }
    td {
      padding: 6px 15px 7px;
      border-bottom: none;
    }

    .changeHeader {
      font-size: 16px;
      font-weight: bold;
      position: relative;

      .headerContent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }

      // &.changes {
      //   color: blue;
      // }

      &.beforeChange {
      }

      &.afterChange {
      }
    }

    .calculation {
      display: flex;
      flex-direction: column;
    }
  }
}

.viewButton {
  margin-left: 8px; /* Adjust spacing as needed */
  padding: 4px 8px; /* Adjust padding as needed */
  height: 10%;
}

.lightText {
  font-size: 12px;
  font-family: 'Titillium Web', sans-serif;
  font-weight: bold;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.reviewal-badge-container {
  display: flex;
  // justify-content: space-between;
  align-items: center;
  color: #616161;
  gap: 10px;

  .icon-container {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 2.5px 5px;
    background-color: #f3f3f3;
    border-radius: 8px;

    .icon-text {
      display: flex;
      align-items: center;
      font-size: 0.8rem;
      font-weight: 700;
      padding: 0;
      margin-bottom: 0;

      .icon {
        height: 0.8rem;
        width: 0.8rem;
      }
    }
  }
}

.jira-tickets:hover {
  background-color: #e0eadd !important;
}

.jira-tickets-not-ack:hover {
  background-color: #e0eadd !important;
}
.icon-black {
  cursor: pointer;
}
.jira-tickets-Wrapper::-webkit-scrollbar {
  width: 0; /* Hide the scrollbar */
  background: transparent; /* Optional: remove background */
}

.drafts-scroll::-webkit-scrollbar {
  width: 0; /* Hide the scrollbar */
  background: transparent; /* Optional: remove background */
}

.custom-checkbox {
  margin-right: 10px;
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: top;
  background-color: #f0f0f0;
  border: 1px solid #d0d0d0;
  position: relative;
  appearance: none; /* Remove default checkbox appearance */
}

.custom-checkbox:checked {
  background-color: rgb(0, 100, 0);
  border-color: rgb(0, 100, 0);
}

.custom-checkbox:disabled {
  background-color: #e0e0e0; /* Background color when disabled */
  border-color: #e0e0e0;
}

.custom-checkbox:disabled:checked {
  background-color: rgb(0, 100, 0);
  border-color: rgb(0, 100, 0);
}

/* Optional: add a checkmark when checked */
.custom-checkbox:checked::after {
  content: '';
  position: absolute;
  top: 3px;
  left: 6px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.title-input-text-box {
  width: auto;
  padding: 0 20px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #d0d0d0;
  margin-top: 0;
  border-radius: 0;
  font-size: 1.5rem;
  max-width: 500px;
  min-width: 200px;

  &:focus {
    // outline: none;
    border: none;
    border-bottom: 1px solid #00534c;
  }

  &::placeholder {
    color: #d0d0d0;
  }

  &:hover {
    border: none;
    border-bottom: 1px solid #00534c;
  }
}

.review-descripion-input-text-box {
  width: auto;
  height: auto;
  padding: 0 20px;
  background-color: transparent;
  border: 1px solid #d0d0d0;
  margin-top: 0;
  padding: 4px;
  border-radius: 6px;
  font-size: 14px;
  // max-width: 500px;
  min-width: 200px;
  min-height: 150px;

  &:focus {
    // outline: none;
    border: 1px solid #00534c;
  }

  &::placeholder {
    color: #d0d0d0;
  }

  &:hover {
    border: 1px solid #00534c;
  }
}

.draftGroupsHeader {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}
.draftGroup {
  border: 1px solid #d1d5da; /* Light border similar to JIRA ticket */
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
  background-color: #ffffff; /* White background */
  transition:
    background-color 0.3s,
    box-shadow 0.3s;
  cursor: pointer;
}
.draftGroup:hover {
  background-color: #f6f8fa; /* Light grey background on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
}

.draftGroup.active {
  border-color: #00534c;
  background-color: #f7faf6;
}

.menuItemFontSize {
  font-size: 12px !important;
}
.menuItemFontSizeMedium {
  font-size: 14px !important;
}

.draftItemWrapper:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
  background-color: #f0f8ff; /* subtle background color change */
  border: 2px solid green;
}

.draftItemWrapper.selected {
  border: 2px solid #1890ff;
}

// .select-hover-text {
//   display: none; /* initially hidden */
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   background-color: rgba(0, 0, 0, 0.75);
//   color: white;
//   padding: 5px 10px;
//   border-radius: 4px;
//   font-size: 12px;
//   pointer-events: none; /* make the select-hover-text non-interactive */
//   z-index: 1;
// }

// .draftItemWrapper:hover .select-hover-text {
//   display: block; /* show select-hover-text on hover */
// }

.overview-container {
  display: flex;
  flex-direction: column;
  // border: 1px solid #d1d5da;
  // border-radius: 5px;
  // gap: 10px;
}

.hoverable-button-container {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 2px;
  border-radius: 6px;
  background-color: rgba(255, 199, 206, 0.4);
  color: rgba(220, 20, 60, 0.6);

  &:hover {
    background-color: rgba(255, 199, 206, 0.8);
    color: rgba(220, 20, 60, 1);
  }
}

.draftItemOneLine {
  font-size: 13px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  //Keep the text on one line
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  // gap: 4px;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // // max-width: 100%; 
  // width: 100%;
}
