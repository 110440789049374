.search-bar {
    border: 1px solid #e0e0e0;
    width: fit-content;
    border-radius: 6px;
    margin-bottom: 10px;
    display: flex;
    justify-items: center;
    align-items: center;
    overflow: hidden;
    height: 40px;

    &.with-focus {
        border: 1px solid #00534C;
    }

    &:hover {
        border-color: #00534C;
    }

}

.input-group-navbar {
    justify-items: center;
    align-items: center;
    flex: 1;

    .searchInput{
        margin: 0px 6px;
        padding: 0;
        box-shadow: none;
        border: 0;
        border-radius: 0px;
        border: none;
        background: none;
        flex: 1;
        min-width: none;
        // padding: $padding_small_medium;
        box-sizing: content-box;
        color: #616161;
        font-size: medium;


        &.clickable{
            &:hover {
                background-color: #E0EADD;
            }
        }

        &:focus {
            box-shadow: none;
            outline: 0;
        }
    }
    .btn {
        margin: 0px 6px 0px 6px;
        padding: 0 0px;
        box-shadow: none;
        border: 0;
        // padding: $padding_small_medium;
        box-sizing: content-box;
        color: #616161;
        // border-radius: 6px;

        &.clickable{
            &:hover {
                background-color: #E0EADD;
            }
        }

        

        &:focus {
            border-color: #00534C;
            box-shadow: none;
            outline: 0;
        }
    }
}