.btn-width {
  min-width: $width_100;
  height: $Height_35;
  white-space: nowrap;
  font-size: $font_medium;
  padding: $padding_small_medium 4px;
  cursor: pointer;
  font-weight: $font-weight-bolder;
  text-align: center;
  font-weight: bold;
  font-family: $font-family;
}

.primary-button {
  @extend .btn-width;
  background-color: $hm_green;
  border: $borderWidth_x_small solid $hm_green;
  color: $white;

  &.fullScreenBtn {
    width: 110px !important;
  }

  &.dynamicWidth {
    width: auto;
    padding-left: 8px;
    padding-right: 8px;
  }

  &.decline {
    background-color: $median_red;
    border: $borderWidth_small solid $median_red;
    color: $white;
    

    &:hover {
      background-color: $color_deleted;
      border: $borderWidth_small solid $median_red;
    }
  }

  &.cancel {
    background-color: $dark_grey;
    border: $borderWidth_small solid $dark_grey;
    color: $white;

    &:hover {
      border: $borderWidth_small solid $medium_dark_grey;
    }
  }

  // &.accept {
  //     background-color: $dark_green;
  //     border: $borderWidth_x_small solid $dark_green;
  //     color: $white;

  //     &:hover {
  //         background-color: $light_green;
  //         border: $borderWidth_x_small solid $light_green;
  //     }
  // }

  &:hover {
    background-color: $hm_green_background;
    color: $black;
    border: $borderWidth_small solid $hm_green;
  }

  &:active {
    background-color: $hm_green !important;
    border-color: $hm_green !important;
  }
}

.workbookParseBtn {
  background-color: $hm_green;
  border: $borderWidth_x_small solid $hm_green;
  color: $white;

  &.fullScreenBtn {
    width: 110px !important;
  }

  &:hover {
    background-color: $hm_green_background;
    color: $black;
    border: $borderWidth_small solid $hm_green;
  }

  &:active {
    background-color: $hm_green !important;
    border-color: $hm_green !important;
  }
}

.red-border-button {
  @extend .btn-width;
  background-color: $white;
  border: $borderWidth_x_small solid $median_red;
  color: $median_red;

  &:hover {
    background-color: $median_red;
    color: $white;
    border: $borderWidth_x_small solid $median_red;
  }

  &:active {
    background-color: $median_red !important;
    border-color: $median_red !important;
  }
}

.secondary-red-background-button {
  @extend .btn-width;
  background-color: $hm_green_background;
  border: $borderWidth_x_small solid $median_red;
  color: $median_red;

  &:hover {
    background-color: $white;
    color: $median_red;
    border: $borderWidth_x_small solid $median_red;
  }

  &:active {
    background-color: $white !important;
    border-color: $median_red !important;
  }
}

.red-background-button {
  @extend .btn-width;
  background-color: $median_red;
  border: $borderWidth_x_small solid $median_red;
  color: $white;

  &:hover {
    background-color: $white;
    color: $median_red;
    border: $borderWidth_x_small solid $median_red;
  }

  &:active {
    background-color: $white !important;
    border-color: $median_red !important;
  }
}

.primary-button-small {
  @extend .primary-button;
  width: $width_90;
}

.btn:disabled,
fieldset:disabled .btn {
  background-color: $hm_green_accent;
  border-color: $hm_green_accent;
}

.secondary-button {
  @extend .btn-width;
  background-color: $hm_green_background;
  border: $borderWidth_small solid $light_grey;
  color: $black;

  &:hover {
    background-color: $hm_green;
    color: $white;
    border: $borderWidth_small solid $hm_green;
  }

  &:active {
    background-color: $hm_green_background !important;
    border-color: $hm_green_background !important;
  }
}

.secondary-button-dialog {
  @extend .secondary-button;
  border: $borderWidth_small solid $hm_green;
}

.secondary-button-gray {
  @extend .secondary-button;
  color: $grey;
  border: $borderWidth_small solid $hm_green_accent;

  &:hover {
    background-color: $light_grey;
    border: $borderWidth_small solid $hm_green_accent;
    color: $grey;
  }
}

.secondary-button-small {
  @extend .secondary-button;
  width: $width_90;
}

.secondary-button-large {
  @extend .secondary-button;
  width: 120px;
}

.secondary-button-range {
  @extend .secondary-button-large;
  border: $borderWidth_small solid $hm_green;
  color: $hm_green;
}

.secondary-button-full {
  @extend .secondary-button;
  width: 100%;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  text-decoration: underline;
  color: $hm_green_accent;
  background-color: $white;
  border-radius: $borderRadius_small;
  margin: 0 0 $marginSmall;

  &:hover {
    background-color: $off_white;
    color: $hm_green_accent;
  }
}

.secondary-button-small-border {
  @extend .secondary-button;
  border: $borderWidth_x_small solid $hm_green;
  width: $width_90;

  &.cancel-button {
    border: $borderWidth_x_small solid $median_red !important;
    width: $width_90;
    color: $median_red !important;

    &:hover {
      background-color: $hm_green;
      color: $white !important;
      border: $borderWidth_small solid $hm_green !important;
    }
  }

  &.dismiss-button {
    border: $borderWidth_x_small solid $median_red !important;
    background-color: $median_red;
    width: $width_90;
    color: $white !important;

    &:hover {
      background-color: $hm_green;
      color: $white !important;
      border: $borderWidth_small solid $hm_green !important;
    }
  }

  &:hover {
    background-color: $hm_green;
    color: $white;
    border: $borderWidth_x_small solid $hm_green;
  }
}

.login-button {
  @extend .primary-button;
  float: right;
  height: $Height_40;
  text-align: center;
  margin-right: $marginMedium_double;
}

.btn-rightMargin {
  margin-right: $marginMedium;
}

.btn-active {
  font-family: $font-family;
  background-color: $hm_green_accent;
  border: $borderWidth_x_small solid $hm_green_accent;
  color: $white;

  &:hover {
    background-color: $hm_green_accent;
    border: $borderWidth_x_small solid $hm_green_accent;
    color: $white;
  }

  &:active {
    background-color: $hm_green_accent !important;
    border-color: $hm_green_accent !important;
  }
}

.newRouteButton {
  border: $borderWidth_1 solid $light_grey !important;
  border-radius: $borderRadius_x_small !important;
  margin-bottom: $marginLarge !important;

  &:hover {
    color: $white !important;
    background-color: $hm_green !important;
  }
}

.newRouteButton.override-margin {
  margin-bottom: 0 !important;
}

.filtBtn {
  width: 'auto' !important;
  min-width: 100px !important;
}

.filtCloseIcon {
  &:hover {
    color: $white !important;

    .filtCloseIcon.pairedProtocol-cancel-icon {
      color: $white !important;
    }
  }
  &.pairedProtocol-cancel-icon {
    margin-right: 5px;
  }
}

.hoverable-icon-button {
  cursor: pointer;
  border-radius: $borderRadius_small;
  padding: $padding_small $padding_medium;
  &:hover {
    color: $hm_green;
  }

  &.red {
    color: $median_red;
    &:hover {
      background-color: $color_deleted;
    }
  }
}
