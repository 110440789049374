.device-mockup-container {
  .gs-container {
    max-height: 35px;
  }
  .gs-item-text {
    font-size: 13px;
  }
}

.device-mockup-title {
  padding-top: 50px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  font-weight: bold;
  // text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  
  //Keep it to one line and use ellipsis if it is too long
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
