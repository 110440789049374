.header-logo-container {

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-left: 26px;

  .lock-icon {
      position: relative;
      right: 22px;
      top: 5px;
      padding: 5px;
      height: 1.25rem;
      width: 1.25rem;

  }
}
