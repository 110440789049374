/* Popup style */
.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
  }
  
  .box {
    position: relative;
    width: 500px;
    margin: 0 auto;
    height: fit-content;
    max-height: 75vh;
     /* margin-top: calc(100vh - 75vh - 60px);*/
     margin-top:8%;
    background: #e0eadd;
    border-radius: 4px;
    padding: 5px 15px 15px 15px;
    border: 1px solid #999;
    overflow: auto;

    &.confirmModalBox {
       margin-top:16%;
    }

  }
  
  .close-icon {
    content: 'x';
    cursor: pointer;
    font-size: 25px;
    float: right;
    font-family: 'Titillium Web', sans-serif;
    font-weight: bold;
    color: #00534C;
    margin: 0 20px 0 0;
    padding: 0;
    /* position: fixed;
    right: calc(35% - 30px);
    top: calc(100vh - 85vh - 33px);
    background: #ededed;
    width: 25px;
    height: 25px;
    text-align: center;
    font-size: 20px; */
  }