.custom-pdf-upload {
  display: flex;
  align-items: center;
  width: 100%;
}

.pdfInputBox {
  flex: 1;
  padding: $padding_small_medium;
  border-top: $borderWidth_x_small solid $white;
  /* border: none !important; */
  border-right: $borderWidth_x_small solid $white;
  border-left: $borderWidth_x_small solid $white;
  border-bottom: $borderWidth_small solid $grey;
  border-radius: $border_radius_4;
  margin-bottom: $marginSmall;

  &:focus {
    box-shadow: none !important;
    border-top: $borderWidth_x_small solid $white;
    /* border: none !important; */
    border-right: $borderWidth_x_small solid $white;
    border-left: $borderWidth_x_small solid $white;
    border-bottom: $borderWidth_small solid $grey;
  }

  &:focus-visible {
    outline: none !important;
  }

  &:active {
    box-shadow: none !important;
    border-top: $borderWidth_x_small solid $white;
    /* border: none !important; */
    border-right: $borderWidth_x_small solid $white;
    border-left: $borderWidth_x_small solid $white;
    border-bottom: $borderWidth_small solid $grey;
  }
}

.pdf-name {
    font-size: 20px;
    font-family: "Titillium Web", sans-serif;
    font-weight: bold;
    margin-left: 30px;

    color: $dark_grey;

    &:hover {
        color: $hm_green;
        cursor: pointer;
    }
}

.custom-button {
  display: inline-block;
  background-color: $hm_green;
  color: $white;
  padding: $padding_8 $padding_16;
  border: none;
  border-radius: $border_radius_4;
  cursor: pointer;
}

.inputLabelAlign {
  display: flex;
  width: 25%;
  justify-content: end;
}

///search

.search-custom-input {
  display: flex;
  align-items: center;
  margin-bottom: $marginMedium_large;
  border: $borderWidth_x_small solid $light_grey;
  padding: $padding_small_medium;
  border-radius: $borderRadius_x_small;
}

.search-inputs {
  flex: 1;
  border: none;
  padding: $padding_1;
  outline: $white !important;
  outline-offset: 0 !important;
  box-shadow: 0 0 0 0.2rem $white !important;
}

.icon_search_pad+.p-inputtext {
  border: none !important;

  &:hover {
    border: none !important;
  }
}

.icon_search_pad {
  margin-right: $marginSmall;
  border-right: $borderWidth_x_small solid $grey;
  width: 15%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding: $padding_small_medium 0px $padding_small_medium 0px;
}

.close-icon {
  margin-right: $marginMedium_large;
  /* Adjust the spacing as needed */
}

.search-icon-align {
  margin-left: $marginMedium_large;
  /* Adjust the spacing as needed */
}

/* custom background color for the values inside Input box */
.selectionArea {
  font-size: $font_15;
  font-family: $font-family;
  border: $borderWidth_1 solid $light_grey;
  background-color: $white;
  border-radius: $borderRadius_x_small;
  padding-inline: $paddingSet_medium_large;
  padding-block: $padding_7;
  display: flex;
  height: $Height_33;
}

.selectionArea-hover {
  @extend .selectionArea;
  position: relative;
  cursor: pointer;

  .rightIcon {
    position: absolute;
    right: 0;
    margin-right: $marginMedium_double;
  }

  &:hover {
    border: $borderWidth_1 solid $hm_green_accent;
  }
}

.protocol-hover{
  @extend .selectionArea;
  position: relative;
  height: auto;

  .rightIcon {
    position: absolute;
    right: 0;
    margin-right: 5px;
    justify-content: center;
    margin-top: 10px;
  }

  &:hover {
    border: $borderWidth_small solid $hm_green;
  }
}

.protocol-not-hover{
  @extend .selectionArea;
  position: relative;
  height: auto;

  .rightIcon {
    position: absolute;
    right: 0;
    margin-right: $marginMedium_double;
  }
}

.selectionArea-disable {
  @extend .selectionArea;
  position: relative;
  cursor: default;

  .rightIcon {
    position: absolute;
    right: 0;
    margin-right: $marginMedium_double;
  }

  &:hover {
    border: $borderWidth_1 solid $light_grey !important;
  }
}

.selectionArea-flex {
  @extend .selectionArea;
  display: flex;
  flex: 1;

  .rightIcon {
    position: absolute;
    right: 0;
    margin-right: $marginMedium_double;
  }

  &:hover {
    border: $borderWidth_1 solid $light_grey !important;
  }
}

.selecteHoverNone {
  @extend .selectionArea;

  .rightIcon {
    right: 0;
    margin-right: $marginMedium_double;
  }

  &:hover {
    border: none;
  }
}

.routeText {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-weight: $font-weight-semibold;
  font-family: $font-family
}