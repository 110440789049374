/* PROTOCOl */
.protocolHeading {
  border-bottom: $borderWidth_small solid $light_grey;
  margin-bottom: $marginMedium_large;
  padding-bottom: $padding_medium_large;
  display: flex;
  justify-content: space-between;
}

.headerContainer {
  padding: $padding_xxl_large $padding_xl_large $padding_medium;
  margin: 0px $marginLarge;
}

.headerText {
  color: $black !important;
  font-weight: $font-weight-bolder;
  font-family: $font-family;
  margin: 0 0 0px 0;
  display: flex;
  align-items: center;
}

.department-logo-header {
  width: auto;
  max-width: 90px;
  max-height: 90px;
  height: auto;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  // object-fit: cover;
  aspect-ratio: 1;
  background-color: #f8f9f9;
  margin: 0px 10px 0px 10px;
}

.headerTextLight {
  color: $black;
  font-weight: 600;
  font-family: $font-family;
  margin: 0 0 $marginSmall 0;
  display: flex;
  align-items: center;
}

.backTextLight {
  color: $dark_grey;
  font-weight: 600;
  font-family: $font-family;
  margin: 0 0 $marginSmall 0;
  display: flex;
  align-items: flex-end;
}

.titleText {
  @extend .headerTextLight;
  font-size: 24px !important;
  font-weight: 600;
  color: $black;
}

.titleTextPH {
  // @extend .headerTextLight;
  font-size: 24px !important;
  font-weight: 600;
  color: $black;
  display: inline-flex; /* Change to inline-flex */
  align-items: center;
  font-family: 'Titillium Web', sans-serif;
  // flex-shrink: 0;
}

.hoverText {
  @extend .headerTextLight;
  display: block;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;

  &:hover {
    border-radius: $borderRadius_small;
    cursor: pointer;
    background-color: $hm_light_green;
    width: fit-content;
  }
}

.lightText {
  @extend .headerTextLight;
  font-weight: 200;
  font-size: 14px;
  color: $dark_grey;
}

.headerTextMargin {
  @extend .headerText;
  margin-bottom: 10px;
}

.protocolHeaderContent {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.protocolHeaderButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-control {
  height: $Height_30;
  background: white;
  border: $borderWidth_1 solid $light_grey;
  border-radius: $borderRadius_x_small;
  padding: $padding_small_medium;
  text-align: center;
  font-size: $font_medium;
  font-family: $font-family;

  &:focus {
    background: $white;
    box-shadow: none;
    outline: 0;
    border: $borderWidth_small solid $light_grey;
  }
}

.form-control-button {
  @extend .form-control;
  width: 100%;
  border-radius: 5px;
  padding: 0px;
  margin-bottom: 20px;
}

.form-control-general {
  @extend .form-control;
  text-align: start;
  font-size: $font_medium;
  font-weight: $font-weight-bold;
  font-family: $font-family;
  width: 100%;
  display: flex;
  padding-left: $paddingSet_small_medium;
  margin-bottom: $marginSmall;
  color: $black;

  &:focus {
    background-color: $white;
    border: $borderWidth_1 solid $hm_green;
  }

  &.error {
    border: $borderWidth_1 solid $red;

    &:focus {
      border: $borderWidth_1 solid $red;
    }

    &:hover {
      border: $borderWidth_1 solid $red;
    }
  }
}

.calculationForm {
  @extend .form-control-general;
  margin-bottom: 0px;
  border-radius: 5px;
  height: 35px;
}

// .input-group-navbar {
//     height: $Height_40;
//     justify-items: center;
//     align-items: center;

//     .form-control,
//     .btn {
//         margin: 0px 6px;
//         padding: 0;
//         box-shadow: none;
//         border: 0;
//         border-radius: 0px;
//         // padding: $padding_small_medium;
//         box-sizing: content-box;
//         color: $hm_green;

//         &.clickable{
//             cursor: pointer;
//             &:hover {
//                 background-color: $hm_light_green;
//             }
//         }

//         &:focus {
//             border-color: $hm_green;
//             box-shadow: none;
//             outline: 0;
//         }
//     }
// }

.searchBar-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

// .input-group-navbar .searchInput {
//     width: 500px !important;
//     height: 100%;
//     text-align: initial !important;
//     color: $black;
//     font-size: medium;
// }

// .search-bar {
//     border: $borderWidth_1 solid $light_grey;
//     width: fit-content;
//     border-radius: $borderRadius_x_small;
//     margin-bottom: $marginMedium_large;
//     display: flex;
//     justify-items: center;
//     align-items: center;
//     overflow: hidden;
//     height: 40px;
// }

.headerButtonContainer {
  display: flex;
  align-items: center;
}

/* DRAGLIST */
.a1SubITems {
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  flex: 1;
  align-items: center;
}

.a1SubItemsContent {
  // width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  &.notDataContentText {
    font-size: 16px;
    justify-content: center;
    align-items: center;
  }
}

.a1dose {
  margin-right: $margin_100;
}

.a1type {
  // margin-right: $margin_30;
  align-items: flex-end;
  justify-self: flex-end;
  overflow: hidden;
}

.a1Content {
  display: flex;
  flex-direction: row;
  margin-left: $margin_32;
}

.listBackground {
  background-color: $hm_green_background;
}

.print-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.print-modal-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  margin: 0;
  padding: 0;
}

.print-modal {
  width: 100%;
  height: 100%;
  // padding: 20px;
  border-radius: 5px;
  // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  overflow-y: scroll;
  flex-direction: row;
  background-color: transparent;
}

.modal-actions {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  padding: 0px 12px;
  background-color: $off-white;
}

.print-modal button {
  margin-top: 0;
  margin-right: 5px;
  cursor: pointer;
}

/* KETAMINE */
.protocolContainer {
  padding: $padding_xl_large;
  margin: $marginMedium_large $marginLarge 0px;
}

.checklist-container {
  margin: 0px $marginMedium_double;
}

.custom-radio-button {
  width: $width_15;
  /* Adjust the width as needed */
  height: $Height_15;
  /* Adjust the height as needed */
  margin-right: $marginMedium_large;
  margin-top: $marginSmall;
  cursor: pointer;
}

.custom-radio-button-input {
  width: $width_15;
  /* Adjust the width as needed */
  height: $Height_15;
  /* Adjust the height as needed */
  margin-right: $marginMedium_large;
  margin-top: $marginSmall;
  cursor: pointer;
}
.custom-checkbox-button-input {
  width: 15px;
  /* Adjust the width as needed */
  height: 15px;
  /* Adjust the height as needed */
  margin-top: $marginSmall;
  margin-left: $marginLarge;
  cursor: pointer;
}

.Review {
  font-size: $font_weight_small;
}

.review-info {
  font-weight: $font-weight-bolder;
  font-size: $font_title;
  margin-left: 15px;
}

.review-details {
  margin-left: 40px;
}

.radio-btn {
  justify-content: center;
  align-items: center;
  font-size: $font_15;
  font-family: $font-family;
}

.radio-button-label {
  font-size: $font-20;
  font-family: $font-family;
  font-weight: bold;
  margin: $marginMedium_large $marginLarge;
}

// Checklist

.checklist_pad {
  border: $borderWidth_x_small solid $grey;
  border-radius: $borderRadius_small;
  padding: $paddingSet_medium_large;
  cursor: pointer;
}

.checklist_urgent_backgeound {
  background-color: $median_red;
}

.verticalBar {
  border-left: $borderWidth_x_small solid $black;
  height: 100%;
}

.guestPdfButton {
  position: absolute;
  right: 0;
  margin-right: $margin_58;
}

.filter_Icon_Pad {
  margin-left: $marginLarge;
}

.SubButtonText {
  font-size: $font_medium;
  font-family: $font-family;
  font-weight: bold;
  padding: 8px 8px;
  // margin: $marginMedium_double $marginSmall;
  align-items: center;
  display: flex;
  cursor: pointer;

  &:hover {
    background-color: $hm_light_green;
    border-radius: $borderRadius_small;
  }
}

.subBtnBorder {
  border: $borderWidth_1 solid $grey;
  border-radius: $borderRadius_x_small;
  position: absolute;
  background-color: $white;
  z-index: 10;
}

.subBtnPad {
  margin: $margin_-10 0px;
}

/*Datatable*/
.p-datatable {
  cursor: pointer;
  font-family: $font-family;
  border-radius: 40px;
}

.p-datatable thead th div {
  justify-content: left;
}

.p-datatable th {
  background-color: $white;
  text-decoration: underline;
  text-decoration-color: $grey;
  color: $dark_grey !important;
  font-family: $font-family;
}

.p-datatable tbody td {
  text-align: left;
  border: 0px !important;

  &:last-child {
    text-align: end;
  }

  &:first-child {
    padding-right: 0;
    text-align: right;
    width: $width_50;
  }
}

.fileColumnWidth {
  width: $width_120 !important;
}

.p-datatable-tbody {
  // border: $borderWidth_x_small solid $light_grey;
  border-radius: 40px !important;
}

.p-datatable-tbody tr {
  border-radius: 40px;
  border-top-left-radius: 1rem;
  border: $borderWidth_1 solid $light_grey;
}

.iconColumn {
  padding-top: 0px;
  padding-bottom: 0px;

  div {
    height: $Height_56;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.nameColumn {
  padding-top: 0px;
  padding-bottom: 0px;

  div {
    height: $Height_56;
    display: flex;
    align-items: center;
  }
}

.p-sortable-column-icon {
  color: $hm_green_accent;
}

/*BreadCrumbs*/
.headerContainer {
  display: flex;
  flex-direction: row;
  margin-right: $marginLarge;
  padding: 0;
}
.breadCrumbsContainer {
  display: flex;
  flex: 4;
  align-items: center;
  border: $borderWidth_x_small solid $light_grey;
  border-radius: $borderRadius_x_medium;
  margin-right: $marginLarge;
  box-shadow:
    rgba(0, 0, 0, 0.06) 0px 1px 3px,
    rgba(0, 0, 0, 0.12) 0px 1px 2px;
  color: $dark_grey;
  padding: 0 $padding_8;
}

.breadCrumbText {
  font-size: $font_medium_large;
  font-weight: $font-weight-semibold;
  font-family: $font-family;
  display: flex;
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 0;
  padding-bottom: 0;

  &:hover {
    color: $hm_green;
    background-color: $hm_light_green;
    border-radius: $borderRadius_medium;
    // border-bottom: $borderWidth_small solid $grey;
  }
}

.breadCrumbIcon {
  display: flex;
  margin-top: $marginSet_small;
}

/*Ketamine*/
.KetamineGeneral {
  border: $borderWidth_1 solid $light_grey;
  border-radius: $borderRadius_x_medium;
  padding: $padding_medium_large $padding_vertical;
  flex: 1;
  margin: 0 $marginMedium_large;

  &.noBorder {
    border: none;
  }
}

.overflow-info-second {
  overflow-y: auto;
  height: 92%;
}

.Equipment-overflow-info-container {
  overflow-y: auto;
  height: 100vh;
}

.ketamineContent {
  display: flex;
  flex: 1;
}

.ketamine-input-content {
  display: grid;
  grid-template-columns: 0.4fr 1fr 1fr 1fr;
  gap: 15px;
  margin: 10px 0px;
}

.equipment-icon-grid {
  display: grid;
  grid-template-columns: 0.3fr 1fr 1fr;
  gap: 15px;
  margin: $marginMedium_large 0px;
}

.ketamine-input-content-equipment {
  display: grid;
  grid-template-columns: 0.15fr 1fr;
  gap: 15px;
  margin: $marginMedium_large 0px;
}
.ketamine-input-twocontent {
  display: grid;
  grid-template-columns: 0.25fr 1fr 1fr;
  gap: 15px;
  margin: $marginMedium_large 0px;
}

.model-input-container {
  position: relative;
  border-radius: $borderRadius_small;
  background-color: $background_grey;
  cursor: default;
}

.hoverableBackground {
  &:hover {
    background-color: $hm_green_background;
  }
}

.doseGridPad {
  padding: 0px 10px 5px 10px;
}

.ketamine-label {
  font-size: $font_medium;
  font-family: $font-family;
  color: $dark_grey;
  display: flex;
  justify-content: center;
}

.equipmentValue {
  text-align: start;
  padding-left: 10px;
}

.ketamine-label-equipment {
  @extend .ketamine-label;
  justify-content: flex-start;
  margin-left: 0px;
  width: 100%;
}

.ketamine-general-label {
  font-size: $font_medium_large;
  font-family: $font-family;
  color: $black;
  display: flex;
  margin-bottom: $marginSmall;
  font-weight: bold;
  margin-left: 10px;
  margin-top: 5px;
}

.ketmine-normal-label {
  font-size: $font_medium_large;
  font-family: $font-family;
  color: $dark_grey;
  display: flex;
  align-items: center;
}

.ketmine-header-text {
  @extend .headerTextLight;
  text-decoration: underline;
  text-underline-position: under;
  margin-bottom: 10px;
}

.instruction-label {
  @extend .ketmine-normal-label;
  color: $black;
  font-weight: $font-weight-bolder;
  margin-right: $marginSmall;
}

.addDoseHeader {
  margin-top: $marginMedium_double;
  display: flex;
  justify-content: space-between;

  a {
    font-size: $font_medium_large;
    font-family: $font-family;
    padding-right: $padding_medium_large;
    text-decoration: underline !important;
    text-decoration-color: $hm_green_accent !important;
    color: $hm_green_accent !important;
  }
}

.dose-header {
  //Make it have a shadow box
  background-color: #f7f8f8;
  // border: $borderWidth_1 solid $light_grey;
  border-radius: $borderRadius_x_medium;
  padding: $padding_medium $padding_medium_large;
  // margin: $marginMedium_large 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 5px 0px $light_grey;
}

/*UnderLine*/
.input-container {
  position: relative;

  .calculationInput {
    width: $width_250;
  }
}

.dialogInput {
  width: 100%;
  border-radius: 0px;
  border-top-right-radius: $borderRadius_x_small;
  // border-bottom-right-radius: $borderRadius_x_small;
}

.rangeContainer {
  width: 100%;
}

.input-border {
  // position: absolute;
  // top: 22px;
  // left: 0;
  // right: 0;
  // height: $Height_1;
  // background-color: $light_grey;
  // margin: 0 $marginMedium_double;
}

.dialogInput-border {
  @extend .input-border;
  top: 30px;
  margin: 0 $marginSmall;
}

.formAnchorText {
  text-decoration: underline;
  font-family: $font-family;
  font-size: $font_medium;
  text-decoration-color: $hm_green_accent !important;
  color: $hm_green_accent !important;
  cursor: pointer;

  &:hover {
    text-decoration-color: $hm_green_accent_dark;
    color: $hm_green_accent_dark;
  }

  &:focus {
    text-decoration-color: $hm_green_accent_dark;
    color: $hm_green_accent_dark;
  }
}

.greyText {
  color: $dark_grey !important;
}

.redText {
  color: $red !important;
}

.form-control:disabled {
  background-color: $white !important;
}

.extractDetailsModal {
  margin: 20px;
  padding: 50px 0px;
  border: 2px solid #cdc8c8;
  display: flex;
  width: 20%;
  border-radius: 10px;
  padding-left: 10px;
}

.dragDropDotGridModal {
  margin: 20px;
  padding: 50px 0px;
  border: 2px dotted #cdc8c8;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding-right: 10px;
  flex-direction: column;
}

.dragDropDotGridModalCustomPdf {
  padding: 50px 0px;
  border: 2px dotted #cdc8c8;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 10px;
  padding-right: 10px;
  flex-direction: column;
}

.addFromWorkbookContainer {
  padding: 50px 0px;
  border: 2px dotted #cdc8c8;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 10px;
  padding-right: 10px;
  flex-direction: column;
  cursor: pointer;

  &:hover {
    background-color: $hm_light_green;
    border-color: $hm_green;
  }
}

.dragDropDotGridNewWorkbook {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  margin-right: 10px;
}

.dragDropDotGridNewWorkbookDetails {
  display: flex;
  flex: 1;
  height: 100%;
  border-radius: 10px;
  flex-direction: column;
  border: 1px solid #cdc8c8;
  padding: 10px;
  margin-left: 12px;
}

.dragDropDotGridModalWorkbook {
  border: 2px dotted #cdc8c8;
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
  border-radius: 10px;
  flex-direction: column;
}

/*Input Text Primereact*/
.p-inputtext {
  border: $borderWidth_1 solid $light_grey;
  color: $black;
  font-weight: 500;
  font-size: $font_medium_large;
  font-family: $font-family;

  a {
    color: $hm_green_accent !important;
  }

  &:hover {
    border: $borderWidth_1 solid $hm_green_light;
  }

  &:focus {
    box-shadow: none;
    border-color: $hm_green;
  }

  &.p-inputtextborderNone {
    border: $borderWidth_1 solid $white !important;
  }
}

.inputHover {
  &:hover {
    border: $borderWidth_1 solid $hm_green_accent !important;
  }
}

.p-inputtext + .p-inputgroup-addon {
  border-left: 0px !important;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: $borderRadius_x_small;
  border-bottom-right-radius: $borderRadius_x_small;
}

.p-inputgroup-addon {
  border: $borderWidth_1 solid $light_grey;
  border-top-left-radius: $borderRadius_x_small;
  border-bottom-left-radius: $borderRadius_x_small;
  color: $black;
  white-space: nowrap;
  font-size: $font_medium;
  font-weight: $font-weight-semibold;
  padding: $padding_small_medium;
}

.doseDropdwnWidth {
  width: 30vh;
}

.p-inputgroup-addon-left {
  @extend .p-inputgroup-addon;
  border-right: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 $padding_small_medium;
}

.calculationMinWidth {
  min-width: $width_110;
}

.p-inputgroup-addon + span {
  padding: 0 $padding_small_medium !important;
}

.full-width {
  width: 100% !important;
}

.spanInput {
  border-right: 0px;

  &:hover {
    border-right: 0px;
  }
}

.centerContent {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font-family;
  font-weight: $font-weight-bolder;
}

/*CHECKBOX*/
.p-checkbox-box {
  border-radius: 50%;

  &:hover {
    border-color: $light_grey;
  }

  &:focus-visible {
    outline: none !important;
  }

  .p-focus {
    box-shadow: none !important;
    border-color: $light_grey !important;
  }
}

.p-checkbox-box.p-highlight {
  border-radius: 50% !important;
  border-color: $hm_green;
  padding: $padding_8;
  background: $hm_green;
}

.p-inputswitch.p-focus .p-inputswitch-slider {
  box-shadow: none;
}

.p-inputswitch .p-inputswitch-slider {
  background-color: $light_grey;
}

.p-inputswitch.p-highlight .p-inputswitch-slider {
  background: $hm_green !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: $hm_green;
}

.checkbox-container {
  border: $borderWidth_small solid $light_grey;
  padding: $padding_small_medium;
  border-radius: $borderRadius_x_small;
  margin-bottom: $marginMedium_large;
  display: flex;
  align-items: center;
}

.selectedCheckbox {
  @extend .checkbox-container;
  border: $borderWidth_small solid $hm_green;
  color: $hm_green;
}

.selectedGreybox {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $light_grey;
  border-radius: $borderRadius_small;
  padding-inline: $padding_medium;
  width: fit-content;
  min-width: $width_35;
  padding-block: $padding_x_small;
  margin: -$marginX_small $marginSmall 0px 0px;
  font-size: $font_medium;
  font-family: $font-family;
  color: $black;
  font-weight: $font-weight-bolder;
  cursor: default;

  &.cursorPointerArea {
    cursor: pointer;
  }

  &.disabled {
    cursor: default;
    background-color: $light_grey;
    &:hover {
      background-color: $light_grey;
    }
  }

  &:hover {
    background-color: $grey;
  }
}

.close_icon_color {
  margin-left: $marginSmall;
  color: $median_red;
}

.listhover {
  &:hover {
    background-color: $hm_light_green !important;
  }
}

.createBtnhover {
  &:hover {
    background-color: $hm_green !important;
    color: $white !important;
  }
}

/* Library Dropdown stying*/
.selectDropdown {
  border: none;
  height: $Height_25;
  width: $width_45;
  ::placeholder {
    color: $light_grey;
    font-size: $font_medium;
    font-family: $font-family;
    font-weight: $font-weight-semibold;
  }
}

.p-dropdown-trigger {
  width: min-content;

  svg {
    width: $width_12;
    height: $Height_12;
  }
}

.p-dropdown-items {
  padding: 0px;
  margin-bottom: 0px;

  li {
    padding: $padding_small;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: $borderWidth_x_small solid $light_grey;
    height: $Height_25;
    font-size: $font_medium_large;
    font-family: $font-family;

    &:last-child {
      border-bottom: none;
    }
  }
}

.p-dropdown-label.p-inputtext {
  border: none;
  font-size: $font_medium_large;
  padding: $padding_x_small;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $black;
  font-weight: $font-weight-semibold;
  font-family: $font-family;

  &:hover {
    border: none !important;
  }
}

.p-dropdown-label.p-inputtext.p-placeholder {
  color: $grey;
}

.p-dropdown-panel.p-component.p-ripple-disabled.p-connected-overlay-enter-done {
  margin-top: $marginMedium_large !important;
  margin-left: -$marginSmall !important;
}

.flex_wrap {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.iconPosition {
  right: 15px;
  position: absolute;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0px !important;
  margin: 0px !important;
}

// Author: Guruprasad Venkatraman (01-05-2024)
// Modified the CSS height and ensured line-height is rendered correctly
// to properly show the background color of the selected row irrespective of the screen sizes.
.column_selected {
  display: flex;
  align-items: center;
  background: $hm_green_accent;
  color: $white;
  padding: 0 1rem;
  justify-content: flex-start;
  height: 4rem;
}

.list_column_selected {
  @extend .column_selected;
  border-bottom-color: $white !important;
}

.list_column_selected_Checklist {
  @extend .column_selected;
  margin: 0px;
}

.row_unselected {
  width: 100%;
  display: flex;
  align-items: center;
  height: auto;
  min-height: 3rem;
  // padding: 0 1rem;
  padding: 0px 20px;
}

.row_unselected .spanFormat {
  width: 100%;
  text-align: center;
}

.column_unselected {
  display: flex;
  align-items: center;
  color: $black;
  height: 2rem;
  padding: 0 1rem;
  justify-content: flex-start;
}

.underline {
  border-bottom: $borderWidth_1 solid $light_grey;
}

.column_unselected1 {
  display: flex;
  align-items: center;
  background: $white;
  color: $black;
  height: 1.8rem;
  padding: 0 1rem;
  justify-content: flex-start;
}

.column_selected > div,
.column_unselected > div {
  line-height: 4rem;
}

.dose-row {
  display: flex;
  width: 100%;
  justify-content: center;
}

.rowLabelWidth {
  width: 20%;
}

.rowContentWidth {
  width: 80%;
}

.minDoseGap {
  .dialogContent {
    gap: 30px !important;
  }
}
.minDoseGap {
  .dialogContent {
    gap: 30px !important;
  }
}

.equipmentdosePad {
  padding: 10px 5px;
}

.equipmentOptionsContainer {
  overflow-y: auto;
}

.dropdownGreyText {
  .p-dropdown-label.p-inputtext {
    color: $light_grey;
  }
}

.sideoutFieldPad {
  margin: -10px 10px !important;
}

.electrical-input-Dose {
  display: grid;
  grid-template-columns: 0.3fr 1fr 1fr;
  gap: 15px;
  padding: 5px 0px;
}

.checklist-input-Dose {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
  padding: 5px 0px;
}

.electrical-input-content {
  display: grid;
  grid-template-columns: 0.47fr 1fr 1fr 1fr;
  gap: 15px;
  margin: 10px 0px;
}

input[type='radio'] {
  accent-color: $hm_green;
}

.fixedHeaderPad {
  margin: 0px 0px !important;
}

.backgroundHidden {
  z-index: 0 !important;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.infolist {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  font-weight: bold;
  font-family: $font-family;
  padding: 5px 15px;
}

.btn_Bottom {
  margin-right: 25px;
  position: absolute;
  display: flex;
  bottom: 0;
  margin-bottom: 10px;
  right: 0;

  &.review_btn_Bottom {
    left: 0px;
    margin-bottom: 25px !important;
    margin-left: 25 !important;
    margin-left: 25px !important;
  }
}

input[type='checkbox']:checked {
  accent-color: $hm_green;
}

.dragging {
  border: 2px solid #3498db; /* You can customize the border color */
  border-radius: 4px;
}

.checkboxgrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.checkboxgrid-single {
  display: grid;
  grid-template-columns: 1fr;
}
.checklist-twocontent {
  display: grid;
  grid-template-columns: 2fr 2fr;
  font-size: 11px;
  margin-bottom: 0px;
  font-weight: bold;
}
.checklist-threecontent {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  font-size: 11px;
  margin-bottom: 0px;
  font-weight: bold;
}
.checklis-title-lable {
  font-size: 11px;
  margin-left: 13px;
  font-weight: bold;
}

//form structure
.pricing-toggle {
  background-color: $background_grey;
  padding: 2px 5px;
  border-radius: 4px;
  display: flex;
  margin-left: 65px;
}

.pricing-toggle [name='pricing'] {
  display: none;
}

.pricing-toggle input[type='radio'] + label {
  color: $dark_grey;
  padding: 0px 14px;
  border-radius: 5px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.pricing-toggle input[type='radio']:checked + label {
  background-color: white;
  color: green;
}

// Form Type
.pricing-toggle-formtype {
  background-color: $background_grey;
  padding: 2px 5px;
  border-radius: 4px;
  display: flex;
  margin-left: 32px;
  justify-content: center;
  align-items: center;
}

.tab-container {
  background-color: $background_grey;
  padding: 2px 2px;
  border-radius: 8px;
  display: flex;
  margin-left: 32px;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.tab {
  color: $dark_grey;
  padding: 0px 12px;
  border-radius: 5px;
  cursor: pointer;
  height: 100%;
}

.tab-active {
  background-color: white;
  color: $hm_green;
}

.tab-active-red {
  background-color: white;
  color: $median_red;
}

.tab-active-green {
  background-color: white;
  color: green;
}

.pricing-toggle-formtype [name='pricingForm'] {
  display: none;
}

.pricing-toggle-formtype input[type='radio'] + label {
  color: $dark_grey;
  padding: 0px 12px;
  border-radius: 5px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.pricing-toggle-formtype.normal input[type='radio']:checked + label {
  background-color: white;
  color: green;
}

.pricing-toggle-formtype.urgent input[type='radio']:checked + label {
  background-color: white;
  color: $dark_red;
}

.checkListTitle {
  font-size: 14px;
  font-weight: bold;
  justify-content: flex-start;
  font-family: 'Titillium Web', sans-serif;
  color: #616161;
  display: flex;
  margin-top: 10px;
}
.checklistInput {
  display: flex;
  background-color: $white;
  border-radius: 10px;
  margin: 5px 5px 5px 0px;
}

p-inputtext-border {
  border: 2px solid $white !important;
}

.urgentTextColr {
  color: $median_red !important;
}

.no-data-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  // height: 80%;
  // width: 100%;
  flex-direction: column;
}

.light-grey-icon {
  color: $grey;
}

.load svg {
  fill: #00534c;
  margin: 0 0 0 0;
  padding: 0 0 0 0;

  &.light {
    fill: $hm_green_accent;
  }
}

.medication-title {
  font-size: small;
  font-weight: 400;
  font-family: 'Titillium Web', sans-serif;
  color: $dark_grey;
  display: flex;
  // flex: 1;
  //Add ellipsis
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // margin-top: 10px;

  &.warning {
    color: $dark_red;
  }
}

.medication-description {
  font-size: small;
  font-weight: 400;
  font-family: 'Titillium Web', sans-serif;
  color: $dark_grey;
  display: flex;
  // margin-top: 10px;
  //One line only
  margin: 6px 0 0 0;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-content: flex-end;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;

  &.transparent {
    color: transparent;
  }
}

.table-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  height: 1.5rem;
  width: 1.5rem;
  color: #636363;
}

.protocol-header-logo-container {
  position: absolute;
  top: -20px;
  right: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;

  .lock-icon {
    position: relative;
    right: 20px;
    top: -15px;
    padding: 5px;
    height: 1.25rem;
    width: 1.25rem;
  }
}

.protocol-header-logo-hover {
  position: absolute;
  top: 30px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hoverable-button-with-text {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  color: #000;
  flex: 1;
  border: 1px solid #e0e0e0;
  cursor: pointer;

  &:hover {
    background-color: $hm_light_green;
    color: $hm_green;
  }

  .hoverable-button-text {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Titillium Web', sans-serif;
    margin: 0;
    padding: 0;
    // fontSize: '16px',

    // fontWeight: 500,
    // margin: 0,
    // padding: 0,
  }

  &.disabled {
    cursor: default;
    // color: #E0E0E0;
    &:hover {
      background-color: #fff;
      color: #000;
    }
  }
}

.arrow-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 8px;
  transition:
    background-color 0.2s,
    opacity 0.2s;
  opacity: 0;
  width: 36px;
  height: 36px;
}

.arrow-container.visible {
  opacity: 1;
}

span:hover .arrow-container:not(.visible) {
  opacity: 1;
}

.arrow-container:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.arrow-container:active {
  background-color: rgba(0, 0, 0, 0.08);
}

.arrow-icon {
  width: 0.9em;
  height: 0.9em;
  opacity: 0.5;
}

.in-review-button {
  background-color: $white;
  border: 1px solid $color_archive_text;
  color: $color_archive_text;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  font-family: 'Titillium Web', sans-serif;
  // margin-bottom: 2px;
  // margin-top: -10px;
  margin-left: 10px;

  &:hover {
    background-color: #eaf5f9;
  }
}
