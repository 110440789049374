.backHeader {
    font-weight: $font-weight-semibold;
    margin: 0 0 0px 0;
    display: flex;
    align-items: flex-end;
    font-family: 'Titillium Web', sans-serif;
    color: $dark_grey;
    display: flex;
    align-items: center;
    
}

.hoverable {
    padding: $padding_small_medium $padding_small_medium;
    border-radius: $borderRadius_small;
    &:hover {
        cursor: pointer;
        background-color: $hm_green_background;
    }
}

.btn-cancel {
    background-color: $dark_red;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 20px;
    // margin: 10px;
    cursor: pointer;
    &:hover {
        background-color: $red;
    }

}

.selectableBorder{
    padding: 50px 0px;
    border: 1px solid #cdc8c8;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 10px;
    padding-right: 10px;
    flex-direction: column;
    cursor: pointer;

    &:hover {
        background-color: $hm_light_green;
        // border-color: $hm_green;
    }
}

.user-details {
    margin: 0;
    padding: 0;
}

.copy_icon{
    margin-left: 8px;
    cursor: pointer;
}

.departmentItemNoHover {
    justify-content: space-between;
    align-items: center;
    padding: 20px $padding_small_medium;
    border-radius: $borderRadius_small;
    margin: 0px 20px;
}
.departmentItem {
    justify-content: space-between;
    align-items: center;
    padding: 20px 10px;
    border-radius: $borderRadius_small;
    // margin: 0px 20px;
    &:hover {
        cursor: pointer;
        background-color: $hm_green_background;
    }

    &.lowPadding {
        padding: 10px 10px;
    }
}

.departmentItemText {
    font-weight: 500;
    margin: 0 0 0px 0;
    display: flex;
    align-items: flex-end;
    font-family: 'Titillium Web', sans-serif;
    display: flex;
    align-items: center;

    &.bold {
        font-weight: bold;
    }

}

.departmentItemIcon {
    font-weight: '500';
    margin: 0 0 0px 0;
    display: flex;
    align-items: flex-end;
    font-family: 'Titillium Web', sans-serif;
    display: flex;
    align-items: flex-end;
    margin-right: $marginLarge_double;
    justify-content: flex-end;
}

.formContainer {
    padding: 20px;
  }

form {
    margin: 0 20px;
}

.string-list-item {
    background: $light_grey;
    padding: 5px;
    display: 'flex';
    align-items: center;
    gap: 5px;
    font-size: small;
    border-radius: 5px;

    &:hover {
        cursor: pointer;
        background-color: $dark_grey;
    }
}

.departmentItemTextClickable {
    font-weight: '500';
    margin: 0 0 0px 0;
    display: flex;
    align-items: flex-end;
    font-family: 'Titillium Web', sans-serif;
    display: flex;
    align-items: center;

    &:hover {
        cursor: pointer;
        color: $hm_green_accent;
    }
}

.container {
    position: relative;
    width: 100%;
}

.list-container {
    // position: absolute;
    // width: 95%;
    // border: 1px solid $light_grey;
    // border-radius: 10px;
    // // max-height: 200px;
    // // overflow-y: auto;
    // z-index: 1;
}


.searchContainer {
    /* Add your styles for the search container here */
    display: flex;
    align-items: center;
}

// li {
//     list-style-type: none;
//     font-weight: '500';
//     font-size: small;
//     padding: 0 20px;
//     display: flex;
//     align-items: center;
//     font-family: 'Titillium Web', sans-serif;
//     display: flex;
//     align-items: center;
//     // margin-right: $marginLarge_double;
//     justify-content: flex-start;
//     margin-bottom: 0px; /* add some spacing between form fields */
//     &:hover {
//         cursor: pointer;
//         background-color: $hm_green_background;
//     }
// }

.dropdown {
    position: absolute;
    width: 100%;
    border: 1px solid black;
    max-height: 200px;
    overflow-y: auto;
    background-color: white;
    z-index: 1;
}

.dropdownItem {
    width: 100%;
    padding: 10px;
    cursor: pointer;
    background-color: white;
    border: none;
    text-align: left;
}

.dropdownItem:hover {
    background-color: #f0f0f0;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: lightgray;
    margin: 0;
}

.refresh-icon {
    color: $dark_grey;
    margin-left: 10px;
    &:hover {
        color: $hm_green;
        cursor: pointer;
    }
}

.descriptionText {
    font-weight: '600';
    font-size: small;
    margin: 0 0 0px 0;
    display: flex;
    align-items: center;
    font-family: 'Titillium Web', sans-serif;
    display: flex;
    align-items: center;
    margin-right: $marginLarge_double;
    justify-content: flex-start;
    margin-bottom: 0px; /* add some spacing between form fields */
    color: $dark_grey;
}

/* Tooltip style */
.tooltip {
    position: absolute;
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 1;
    top: 100%; /* Position below the item */
    left: 0;
    display: none;
  }
  
  /* Hover behavior */
  .departmentItemText:hover .tooltip {
    display: block; /* Show the tooltip when hovering over .departmentItem */
  }

  .clickableText {
    /* underline the text*/
    text-decoration: underline;
    color: $hm_green_accent;
    margin-left: 10px;
    &:hover {
        color: $hm_green;
        cursor: pointer;
    }
  }

  .icon-size-cancel {
    font-size: 20px;
    color: $red;
    &:hover {
        color: $dark_red;
        cursor: pointer;
    }
  }

  .messageBox {
    margin: 0 20px;
    padding: 20px;
    border-radius: 3;
    background-color: 'white';
    color: 'black';
    font-size: small;
    font-weight: '500';
    font-family: 'Titillium Web', sans-serif;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  