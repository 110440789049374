.screen-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .content-container {
    display: flex;
    flex: 1;
  }
  
  .left-panel {
    // width: 50%;
    flex: 1;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    // background-color: #f7f7f7;
    // border-right: 1px solid #ddd;
  }
  
  .right-panel {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
  }
  
  .form-group, .form-group-horizontal {
    margin-bottom: 20px;
  }
  
  .form-group-horizontal {
    display: flex;
    justify-content: space-between;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .extract-button {
    padding: 10px 20px;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .extract-button:hover {
    background-color: #0056b3;
  }
  
  .embed-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .hover-background {
    border-radius: 8px;
    padding: 10px;
    display: flex;
    cursor: pointer;
    align-items: center;
    &.even {
      background-color: #f7f7f7;
    }
    &.odd {
      background-color: #fff;
    }
    &:hover {
      background-color: #E0EADD;
    }
    

  }
  // #loading-text {
  //   transition: opacity 0.5s;
  //   opacity: 1;
  
  //   &.fade-out {
  //     opacity: 0;
  //   }
  
  //   &.fade-in {
  //     opacity: 1;
  //   }
  // }
  // #loading-text {
  //   display: inline-block;
  //   position: relative;
  //   transition: transform 0.5s, opacity 0.5s;
  //   opacity: 1;
  
  //   &.slide-out {
  //     transform: translateX(100%);
  //     opacity: 0.0;
  //   }
  
  //   &.slide-in {
  //     transform: translateX(100%);
  //     opacity: 0.0;
  //   }
  
  //   &.slide-in-active {
  //     transform: translateX(0);
  //     opacity: 1;
  //   }
  // }
  #loading-text {
    display: inline-block;
    position: relative;
    transition: transform 0.5s, opacity 0.5s;
    opacity: 1;
    transform: translateX(0);
  
    &.slide-out {
      transform: translateX(0%);
      opacity: 0;
    }
  
    &.slide-in {
      transform: translateX(100%);
      opacity: 0;
    }
  
    &.slide-in-active {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  
  
  