.list-container {
    // display: flex;
    margin: 6px 0px;
    max-height: 62.5vh;
    overflow-y: auto;
}

.model-table-header-container {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background-color: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.model-table-header {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    border-bottom: none;
    font-weight: bold;
    padding: 4px 0px;
    z-index: 100;

    .column {
        flex: 1;
        text-align: start;
        display: flex;
        align-items: center;
        border-right: 1px solid #ccc;
        cursor: pointer;
        
        padding: 0px 10px;

        //Keep to one line otherise use ellipsis
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.checkbox-column {
            padding: 0px 16px;
            margin-left: -8px;
            border-right: 1px solid #ccc;
            
        }
    }
}

.model-table-data {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border-radius: 5px;
    border-bottom: none;
    font-weight: normal;
    padding: 4px 0px;
    cursor: pointer;

    .column {
        flex: 1;
        text-align: start;
        display: flex;
        align-items: center;
        margin-left: 1rem;
        
        //Keep to one line otherise use ellipsis
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.compact {
            height: 1.5rem;
        }

        &.large {
            height: 3rem;
        }

        &.none {
            height: 0px;
        }

        &.normal {
            height: 2.5rem;
        }

        &.checkbox-column {
            padding: 0px 16px;
            margin-left: -10px;
            margin-right: 10px;
            border-right: 1.5px solid #ccc;
        }

    }

    

    &.border-bottom {
        border-bottom: 1px solid #ccc;
    }

    &.selected {
        background-color: #E0EADD;
    }

    &:hover {
        background-color: #E0EADD;
    }

    &.inactive {
        background-color: #f2f2f2;

        &:hover {
            background-color: #E9E9E9;
        }
    }
}

.pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;

    .pagination-controls {
        display: flex;
        align-items: center;
    }

    .pagination-text {
        margin-right: 10px;
        font-size: 14px;
        color: #434343;
    }
}