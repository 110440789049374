.inputModalBodyContainer {
  background-color: $white;
  border-radius: $borderRadius_small;
}

.modalInsideBorder {
  border: $borderWidth_x_small solid $hm_green;
  border-radius: $borderRadius_small;
  padding: $paddingSet_medium_large;
}

.modalLabel {
  font-size: $font_15;
  font-family: $font-family;
  font-weight: bold;
}

.saveDraftModal {
  margin: $marginMedium_double $marginLarge 0px $marginLarge;
}

.modalInput {
  width: 85%;
  padding: $padding_x_small;
  margin: $marginSet_small $marginSmall;
  border-top: $borderWidth_x_small solid $white !important;
  /* border: none !important; */
  border-right: $borderWidth_x_small solid $white !important;
  border-left: $borderWidth_x_small solid $white !important;
  border-bottom: $borderWidth_small solid $grey !important;
  margin-bottom: $marginSmall;

  &:focus {
    box-shadow: none !important;
    border-top: $borderWidth_x_small solid $white !important;
    /* border: none !important; */
    border-right: $borderWidth_x_small solid $white !important;
    border-left: $borderWidth_x_small solid $white !important;
    border-bottom: $borderWidth_small solid $grey !important;
  }

  &:hover {
    color: $black;
    border-right: $borderWidth_x_small solid $white !important;
    border-left: $borderWidth_x_small solid $white !important;
    border-bottom: $borderWidth_small solid $grey !important;
  }

  &:active {
    box-shadow: none !important;
    border-top: $borderWidth_x_small solid $white !important;
    /* border: none !important; */
    border-right: $borderWidth_x_small solid $white !important;
    border-left: $borderWidth_x_small solid $white !important;
    border-bottom: $borderWidth_small solid $grey !important;
  }

}

.textInputContainer {
  border: $borderWidth_x_small solid grey;
  border-radius: $borderRadius_x_small;
  padding-left: $padding_x_small;
  padding-right: $padding_x_small;
  display: flex;
  margin-top: $marginSmall;
}

.babelDivider {
  border-right: $borderWidth_x_small solid $grey;
  margin-left: $marginLarge_double;
}

.labelPosition {
  font-size: $font_medium;
  font-family: $font-family;
  display: flex;
  align-items: center;
}

.modalBtnAlign {
  margin-top: $margin_65;
  /* margin-bottom: 52px; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
}


/* Modal container */
.sidemodal {
  position: fixed;
  top: 0;
  right: -100%;
  width: 25%;
  height: 97%;
  background-color: #fff;
  transition: right 10.10s ease-in-out;
  z-index: 9999;
  margin: $marginMedium_large;
  border-radius: $borderRadius_small;
}

.sidebar-hidden {
  transform: translateX(100);
  transition: transform left 1000s ease-in-out; 
}
/* Background overlay */
.sideoverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black overlay */
  z-index: 9998;
  /* Place it below the modal */
}

/* Show the modal and overlay */
.sidemodal.open {
  right: 0;
}

.sideoverlay.open {
  display: block;
}

.ReactModal__Content.ReactModal__Content--after-open {
  padding: 0px !important;
}

.sidebarFullWidth {
  width: 100%;
  // border-radius: $borderRadius_small;
  // padding-bottom: $padding_xl_large;
  // padding-top: $padding_medium_large;
}

// styling for the Sideout layout
.sidebarWidth {
  width: 40%;
  min-width: 500px;
  border-radius: $borderRadius_small;
  // padding-bottom: $padding_xl_large;
  // padding-top: $padding_medium_large;
}

.ReviewsidebarWidth {
  width: 26%;
  border-radius: $borderRadius_small;
  padding-bottom: $padding_xl_large;
  padding-top: $padding_medium_large;
}

.sidebarText {
  font-size: $font_medium_large;
  font-family: $font-family;
  color: $dark_grey;
  margin: 0px;
}


.sidebarText-hover{
  font-size: $font_medium_large;
  font-family: $font-family;
  color: $dark_grey;
  margin: 0px;

  &:hover {
    color: $hm_green;
    cursor: pointer;
    text-decoration: underline;
  }
}

.p-sidebar-content {
  display: flex;
  flex-direction: column;
}

.sidebarLink {
  text-decoration: underline;
  text-decoration-color: $blue ;
  color: $blue;
}

.siderbarContent {
  border-radius: $borderRadius_x_small;
  padding: 0px $paddingSet_medium_large;
  margin-bottom: $marginLarge;
  height: 100vh;
}

.sidebarInputContainer {
  border: $borderWidth_x_small solid $light_grey;
  // padding: 0 $padding_medium_large $padding_medium_large $padding_medium_large;
  // margin: 0px 0px $marginLarge;
  margin: 0;
  padding: 0;
  border-radius: $borderRadius_x_small;
  background-color: $background_grey;
}

.sidebarChecklistInputContainer {
  border: $borderWidth_x_small solid $light_grey;
  padding: 0 $padding_medium_large $padding_medium_large $padding_medium_large;
  margin: 0px 0px $marginLarge;
  border-radius: $borderRadius_x_small;
  background-color: $background_grey;

   &.urgent {
    background-color: $patient_pink_light;
  }

  &.normal {
    background-color: $background_grey;
  }

}

.sidebarLabel {
  font-size: $font_medium ;
  font-family: $font-family;
  color: $black;
  font-weight: $font-weight-bolder;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &.red {
    color: $dark_red;
  }
}

.sidebarInputContent {
  display: grid;
  grid-template-columns: 0.5fr 4fr;
  gap: 15px;
  margin: $marginMedium_large 0px;
}

.equipmentSidebarInputContent {
  display: grid !important;
  grid-template-columns: 0.5fr 3.5fr !important;
  gap: 15px;
  margin: $marginMedium_large 0px;
}

.electricalsidebarInputContent{
  display: grid !important;
  grid-template-columns: 0.5fr 3.5fr !important;
  gap: 15px;
  margin: $marginMedium_large 0px;
}

.infusionSidebarInputContent {
  display: grid;
  grid-template-columns: 2fr 7fr;
  gap: 15px;
  margin: $marginMedium_large 0px;
}


.sidebarButtons {
  // display: flex;
  position: absolute;
  right: $paddingSet_medium_large*2;
  bottom: $paddingSet_medium_large;
  // justify-content: flex-end;
  // padding: 0px $paddingSet_medium_large;
}

.protocolSidebar .p-sidebar-header {
  display: none !important;
}

.p-sidebar-icons {
  display: none;
}

.p-sidebar-mask {
  padding: $padding_medium_large;
}

.pointer {
  cursor: pointer;
  color: $black;
  font-weight: bold !important;
}

.p-input-icon-right {
  position: relative;
  display: flex;

  
}

.red-border {
  //Add a borde shadow to the input field
  box-shadow: 0 0 3px #ff0000;
  border: 4px solid #ff0000;
  border-radius: $borderRadius_x_small;
}

.p-sidebar-header {
  padding-left: $padding_31;
}

.custom-sidebar {
  transition: transform 0.3s ease-in-out;
}

.custom-sidebar-hidden {
  transform: translateX(-100%);
}


/* custom styling for the modal popups */
.dialogContent {
  font-family: $font-family;
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: $margin_50;
  padding: $padding_medium_large;
}

.calculationDialogContent {
  @extend .dialogContent;
  margin-bottom: $marginMedium_large;
}

.dialogButtons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: $marginMedium_double;
}

.dialogButtonsForce {
  position: absolute;
  bottom: 12px;
  right: 12px;
}

.dialogInputContainer {
  // border: $borderWidth_small solid $hm_green_light;
  padding: 0px $padding_xl_large;
  border-radius: $borderRadius_x_small;
  background-color: $white;
}

.outerDialogDiv {
  padding: $padding_xl_large;
  background-color: $white;
  border-radius: $borderRadius_x_small;
}

.p-dialog-content {
  background-color: $hm_light_green;
}

.p-dialog-header {
  background-color: $hm_light_green;
  padding: $padding_small_medium $paddingSet_medium_large;
  color: $black;

  div {
    padding-left: $padding_30;
  }
}

.p-inputgroup-addon {
  background-color: $white;
}

.confirmDialog {
  .p-dialog-header {
    background-color: $white;
  }

  .p-dialog-content {
    background-color: $white;
    padding-bottom: $padding_xl_large;
  }

}


.parseDialog {
  .p-dialog-header {
    // background-color: $off-white;
    box-shadow: 0 6px 4px 0 rgba(0, 0, 0, 0.2);
  }

  .p-dialog-content {
    flex: 1;
    background-color: transparent;
    padding-bottom: $padding_xl_large;
    padding: 0;
    margin: 0;
  }

}

.routeConfirmModal{
  .p-dialog-header div {
    padding: 10px 10px 0px 8px !important;
  }
}

.routeModal{
  .p-dialog-content{
    overflow-y: hidden;
  }
}

.reviewModal{
  .p-dialog-content {
     background-color: $white !important;
  }
  .p-dialog-header {
    background-color: $white !important;
    padding: 5px 0px;
  }
  .p-dialog-header div {
    padding-left: 11px;
  }
  .p-dialog-header-icons {
    display: none;
  }
  .dialogInputContainer {
  border: $borderWidth_small solid $hm_green_light;
  padding: 0px $padding_xl_large;
  border-radius: $borderRadius_x_small;
  background-color: $white;
}
  .contentText {
  font-size: $font_13;
  font-family: $font-family;
  align-items: center;
  display: flex;
  color: $black;

  &.contentTextBlackColor {
    color: $black;
  }
}
.headerTextMargin div {
  margin-left: 13px !important;
}
}