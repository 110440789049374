.icon-size {
  width: $width_10;
  height: $Height_10;
  margin-bottom: $marginSet_small;
  margin-right: $marginSmall;
}

.view_pdf_icon {
  width: $width_20;
  height: $Height_15;
  margin-bottom: $marginSet_small;
  margin-right: $marginSmall;
}

.icon_medium_small {
  width: $width_20;
  height: $Height_20;
  margin-bottom: $marginSet_small;
  margin-right: $marginSmall;
}

.icon_medium {
  width: $width_25;
  height: $Height_25;
  margin-bottom: $marginSet_small;
  margin-right: $marginSmall;
}

.icon-normal {
  width: $width_15;
  height: $Height_15;
  margin-bottom: $marginSet_small;
  margin-right: $marginSet_small;
}

.icon-black {
  @extend .icon-normal;
  color: $black;

  &.rightIcon {
    right: 0;
    margin-right: 15px;
  }
}

.icon-medium {
  width: $Height_13;
  height: $Height_13;
  margin-bottom: $marginSet_small;
}

.icon-large {
  width: $width_17;
  height: $Height_17;
  margin-bottom: $marginSet_small;
}

.icon-xl {
  width: $width_25;
  height: $Height_25;
  margin-bottom: $marginSet_small;
}

.protocol-icons {
  width: $width_25;
  height: $Height_25;
}

.protocol-vital-icons {
  width: $width_30;
  height: $Height_30;
}

.iconButton {
  display: flex;
  cursor: pointer;
  border-radius: 6px;
  &:hover {
    background-color: $hm_green_background;
  }

  &.disabled {
    cursor: not-allowed;
    background-color: transparent;
  }

  &.redHover {
    &:hover {
      background-color: #f0e0e0;
    }
  }

  .icon {
    padding: 3px;
    width: 29px;
    height: 29px;

    &.disabled {
      cursor: not-allowed;
      color: grey;
    }
  }

  &.disabled {
    cursor: not-allowed;
    // background-color: $light_grey;
    .icon {
      color: $grey;
    }
  }
}

.header-icon {
  width: $width_20;
  height: $Height_20;
  margin-bottom: $marginSet_small;
  margin-right: -$marginSmall;
}

.pairedProtocol-cancel-icon {
  width: $width_20;
  height: $Height_20;
}

.text-icon {
  width: $width_17;
  height: $Height_17;
  margin-bottom: $marginSet_small;
  margin-right: -$marginX_small;
}

.text-icon-checklist {
  width: $width_10;
  height: $Height_17;
  margin-bottom: $marginSet_small;
  margin-right: -1px;
}

.drag-drop-icon {
  width: $width_50;
  height: $Height_50;
  color: $grey;

  .green_color {
    color: $hm_green_accent_light;
  }
}

.date-close-icon {
  margin-left: -65px;
  color: $median_red;
  position: sticky;
  vertical-align: middle;
}
