.login {
    margin: 40px 0 0 0;
    padding: 25px;
    background-color: #FFF;
    border-radius: 4px;
}

.form {
    margin: 0;
    border: 2px solid #00534C;
}

.form .login-menu{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0;
    margin: 0;
}

.form .login-menu li a {
    font-size: 20px;
    padding: 10px;
    margin: 0;
    cursor: pointer;
}

.form ul {
    padding: 0;
    margin: 0;
}
  
.form ul li {
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
}

.form ul li a {
    text-align: center;
    color: #00534C;
    background-color: #FFF;
    text-decoration: none;
    font-size: 16px;
    display: block;
    padding: 2px 15px 0px 15px;
    font-family: 'Titillium Web', sans-serif;
}
  
.form ul li a:hover,
.form ul li a:active {
    background: #e0eadd;
}

.form form{
    background-color: #FFF;
    padding: 10px 0;
    margin: 0 10px;
}

.form .group{
    flex: auto;
    flex-direction: column;
    margin: 10px 0 0 0;
}

.form .inputContainer{
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
}
.form .text-input{
    width: 90%;
    margin: 10px 10px 5px 10px;
    font-size: 16px;
    padding: 10px 10px 10px 50px;
    border-radius: 4px;
    border: 2px solid #c2c7d0;
    font-family: 'Titillium Web', sans-serif;
}
.form .text-input-is-invalid{
    width: 90%;
    margin: 10px 10px 5px 10px;
    font-size: 16px;
    padding: 10px 10px 10px 50px;
    border-radius: 4px;
    border: 2px solid red;
    font-family: 'Titillium Web', sans-serif;
}
.form svg{
    color: #00534C;
    position: absolute;
    margin-top: 10px;
    top: 10px;
    left: 30px;
}

.form .inputContainer select{
    cursor: pointer;
    width: 95%;
    margin: 10px 10px 5px 10px;
    font-size: 16px;
    padding: 10px 10px 10px 50px;
    border-radius: 4px;
    border: 2px solid #c2c7d0;
    font-family: 'Titillium Web', sans-serif;
}

.form .extra-login{
    display: inline-block;
    align-items: center;
    text-align: center;
    margin-left: 20px;
    margin-top: 10px;
}
.form .checkbox {
    cursor:pointer;
    float: left;
    border: 0;
    margin: 3px 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
}
.form .remember {
    margin-left: 20px;
    flex: 0 0 60%;
    display: flex;
}
.form .forgot {
    justify-content: flex-end;
    text-align: end;
    flex: 0 0 30%;
    display: flex;
}
.form .forgot:hover {
    color: #00534C;
    cursor: pointer;
}

.form .create-account {
    border: none;
    width: 90%;
    padding: 10px 17px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    background-color: #E5E1E6;
    color: black;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 10px;
    border-radius: 6px;
    font-family: 'Titillium Web', sans-serif;
}
.form .login-btn {
    cursor: pointer;
    border: none;
    width: 90%;
    padding: 10px 17px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    background-color: #00534C;
    color: #FFF;
    display: block;
    margin: 20px auto 10px auto;
    border-radius: 6px;
    font-family: 'Titillium Web', sans-serif;
}

.form .cancel-btn {
    cursor: pointer;
    border: none;
    width: 90%;
    padding: 10px 17px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    background-color: lightcoral;
    color: #FFF;
    display: block;
    margin: 20px auto 10px auto;
    border-radius: 6px;
    font-family: 'Titillium Web', sans-serif;
}

.form .cancel-btn:hover {
    background-color: #e0eadd;
    color: black;
}

/* form .login-btn:hover {
    background-color: #e0eadd;
    color: black;
} */
.form .forgotTitle{
    text-align: center;
    justify-content: center;
    font-family: 'Titillium Web', sans-serif;
    padding: 0;
    margin: 0;
}

form .login-btn:hover {
    background-color: #F3F7F2;
    color: black;
    border: 1px solid #00534C;
}

.form .reset {
    width: 100%;
    border: none;
    padding: 10px 17px;
    text-align: center;
    text-decoration: none;
    font-size: 12px;
    background-color: #FFF;
    color: black;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 6px;
    font-family: 'Titillium Web', sans-serif;
}
.form .reset:hover {
    background-color: #E5E1E6;
}

.main-login {
    background-color: #00534C;
}

.recaptcha {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 20px 0 0 0;
    padding: 0;
}

.form .login-error {
    background-color: #FFF;
    font-family: 'Titillium Web', sans-serif;
    margin: 10px 0 0 20px;
    font-size: 16px;
    color: red;
}

.form .login-message {
    background-color: #FFF;
    font-family: 'Titillium Web', sans-serif;
    margin: 10px 0 0 20px;
    font-size: 16px;
    color: green;
}

.form .input-error{
    background-color: #FFF;
    font-family: 'Titillium Web', sans-serif;
    margin: 0 10px 0 10px;
    font-size: 13px;
    color: red;
}